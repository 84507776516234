import React, { Component } from "react";
import Global from "../Global.json";
import { thisExpression } from "@babel/types";
export default class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuOpen: false,
      showBanner: false,
      Categories: [],
      MostSelling: [],
      Currencies: [],
      Social: [],
      isPageLoad: true,
      guest_id: localStorage.getItem("guest_id")
        ? localStorage.getItem("guest_id")
        : 0,
      user_id: localStorage.getItem("user_id")
        ? localStorage.getItem("user_id")
        : 0,
      currency: localStorage.getItem("currency")
        ? localStorage.getItem("currency")
        : "SAR",
      language:
        localStorage.getItem("language") != null
          ? localStorage.getItem("language")
          : "arabic"
    };
  }

  componentDidMount() {
    // window.addEventListener('scroll', this.handleScroll);
  }

  handleScroll(e) {
    if (window.pageYOffset > 50 && window.pageYOffset < 900) {
      //console.log("scrolled");
      this.setState({
        showBanner: true
      });
    } else {
      //console.log("Top");
      this.setState({
        showBanner: false
      });
    }
  }

  componentWillMount() {
    if (this.state.language == "arabic") {
      this.setState({
        i18n: Global.ar
      });
    } else {
      this.setState({
        i18n: Global.en
      });
    }

    if (this.state.guest_id == 0) {
      //console.log(this.state.guest_id);
      fetch(Global.api_link + "generateGuestID", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          token: Global.token
        })
      })
        .then(response => response.json())
        .then(result => {
          // console.log(result);
          localStorage.setItem("guest_id", result.data);
        });
    }

    fetch(Global.api_link + "getUserCartCounter", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: Global.token,
        user_id: this.state.user_id,
        guest_id: this.state.guest_id
      })
    })
      .then(response => response.json())
      .then(result => {
        this.setState({
          CartCounter: result.data
        });
      });

    if (!localStorage.getItem("currency")) {
      localStorage.setItem("currency", "SAR");
    }

    {
      /**Mian Category */
    }
    fetch(Global.api_link + "getMainCategories", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language
      })
    })
      .then(response => response.json())
      .then(result => {
        this.setState(
          {
            Categories: result.data,
            isPageLoad: false
          },
          () => {}
        );
      });

    {
      /* Get All Currencies */
    }
    fetch(Global.api_link + "getCurrencies", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: Global.token
      })
    })
      .then(response => response.json())
      .then(result => {
        this.setState(
          {
            Currencies: result.data
          },
          () => {}
        );
      });
  }

  CurrencyChange(Curr) {
    localStorage.setItem("currency", Curr);
    window.location.reload();
    this.setState({
      isPageLoad: false
    });
  }

  Search() {
    window.location.href = "/Search/" + this.state.Key;
  }

  ChangeLanguage() {
    if (this.state.language == "arabic") {
      localStorage.setItem("language", "english");
      window.location.reload();
    } else {
      localStorage.setItem("language", "arabic");
      window.location.reload();
    }
  }

  render() {
    return (
      <div>
        <div className="headerTop">
          <div className="container">
            <div className="row">
              {/* Logo View */}
              <div className="col-md-9 d-block d-md-none headerLeft">
                <div className="col-md-12 col-xs-12">
                  <ul>
                    <li>
                      <div className="dropdown headerLeftCurrency">
                        <a
                          className="btn btn-secondary dropdown-toggle"
                          href="#"
                          role="button"
                          id="dropdownMenuLink"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <img
                            src="/images/arrow_down.png"
                            className="currency_arrow"
                          />{" "}
                          {this.state.currency}
                          <img
                            src="/images/notes.png"
                            className="currency_icon"
                          />
                        </a>

                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuLink"
                        >
                          {this.state.Currencies.map(Curr => {
                            return (
                              <a
                                className="dropdown-item"
                                href="#"
                                key={Curr.id}
                                onClick={() => {
                                  this.setState({ isPageLoad: true }, () => {
                                    this.CurrencyChange(Curr.reference);
                                  });
                                }}
                              >
                                {Curr.reference}
                              </a>
                            );
                          })}
                        </div>
                      </div>
                    </li>

                    <li>
                      <div
                        className="langSwitcher"
                        tabIndex={this.state.language}
                      >
                        {this.state.language != "english" ? (
                          <a
                            onClick={() => {
                              this.setState({ isPageLoad: true }, () => {
                                this.ChangeLanguage();
                              });
                            }}
                          >
                            English
                            <img
                              src="/images/planet-earth.png"
                              className="currency_icon"
                            />
                          </a>
                        ) : (
                          <a
                            onClick={() => {
                              this.setState({ isPageLoad: true }, () => {
                                this.ChangeLanguage();
                              });
                            }}
                          >
                            عربي
                            <img
                              src="/images/planet-earth.png"
                              className="currency_icon"
                            />
                          </a>
                        )}
                      </div>
                    </li>

                    <li>
                      <a className="HeaderCart" href="/Cart">
                        {" "}
                        {this.state.i18n.MyCart}{" "}
                        <img alt="Cart" src="/images/shopping-cart.png" />
                        <span>{this.state.CartCounter}</span>
                      </a>
                    </li>
                  </ul>
                </div>


              </div>
              <div className="col-md-3 col-xs-12">
                <a href="/">
                  <img src="/images/logo.png" className="logo_image" />
                </a>
              </div>

              {/* Desktop Beside logo View */}
              <div className="col-md-9 d-none d-md-block headerLeft">
                <div className="col-md-12 col-xs-12">
                  <ul>
                    <li>
                      <div className="dropdown headerLeftCurrency">
                        <a
                          className="btn btn-secondary dropdown-toggle"
                          href="#"
                          role="button"
                          id="dropdownMenuLink"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <img
                            src="/images/arrow_down.png"
                            className="currency_arrow"
                          />{" "}
                          {this.state.currency}
                          <img
                            src="/images/notes.png"
                            className="currency_icon"
                          />
                        </a>

                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuLink"
                        >
                          {this.state.Currencies.map(Curr => {
                            return (
                              <a
                                className="dropdown-item"
                                href="#"
                                key={Curr.id}
                                onClick={() => {
                                  this.setState({ isPageLoad: true }, () => {
                                    this.CurrencyChange(Curr.reference);
                                  });
                                }}
                              >
                                {Curr.reference}
                              </a>
                            );
                          })}
                        </div>
                      </div>
                    </li>

                    <li>
                      <div
                        className="langSwitcher"
                        tabIndex={this.state.language}
                      >
                        {this.state.language != "english" ? (
                          <a
                            onClick={() => {
                              this.setState({ isPageLoad: true }, () => {
                                this.ChangeLanguage();
                              });
                            }}
                          >
                            English
                            <img
                              src="/images/planet-earth.png"
                              className="currency_icon"
                            />
                          </a>
                        ) : (
                          <a
                            onClick={() => {
                              this.setState({ isPageLoad: true }, () => {
                                this.ChangeLanguage();
                              });
                            }}
                          >
                            عربي
                            <img
                              src="/images/planet-earth.png"
                              className="currency_icon"
                            />
                          </a>
                        )}
                      </div>
                    </li>

                    <li>
                      <a className="HeaderCart" href="/Cart">
                        {" "}
                        {this.state.i18n.MyCart}{" "}
                        <img alt="Cart" src="/images/shopping-cart.png" />
                        <span>{this.state.CartCounter}</span>
                      </a>
                    </li>

                    <li>
                      {this.state.user_id ? (
                        <a className="MyProfile" href="/Profile">
                          {this.state.i18n.MyAccount}{" "}
                          <img
                            src="/images/user.png"
                            className="currency_icon"
                          />
                        </a>
                      ) : (
                        <div className="langSwitcher">
                          <a href="/Login">
                            {this.state.i18n.LoginHead}
                            <span className="topLoginSeparator">/</span>
                            {this.state.i18n.Signup}
                          </a>
                          <img
                            src="/images/login.png"
                            className="currency_icon"
                          />
                        </div>
                      )}
                    </li>
                  </ul>
                </div>

                <div className="col-md-12 col-sm-12 col-xs-12 menuDesktopLeft">
                  <div className="form-inline">
                    <input
                      className="form-control mr-sm-1"
                      type="search"
                      placeholder={this.state.i18n.SearchIn}
                      aria-label="Search"
                      name="key"
                      onChange={key => {
                        this.setState({ Key: key.target.value });
                      }}
                    />
                    <button
                      className="btn btn-outline-success my-2 my-sm-0"
                      type="submit"
                      onClick={() => {
                        this.Search();
                      }}
                    >
                      {this.state.i18n.Search}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Desktop Menu */}
        <div className="menuDesktop d-none d-md-block">
          <div className="container">
            <div className="col-md-12 col-sm-12 col-xs-12 menuDesktopRight">
              <div className="row">
                <ul>
                  <li className="menuDesktopCategories">
                    <li className="nav-item dropdown mega-dropdown active">
                      <a
                        className="nav-link dropdown-toggle"
                        id="navbarDropdownMenuLink5"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {this.state.i18n.Categories}
                        <span className="sr-only">(current)</span>
                      </a>
                      <div
                        className="dropdown-menu mega-menu v-3 z-depth-1 primary-color-dark py-5 px-3"
                        aria-labelledby="navbarDropdownMenuLink5"
                      >
                        <div className="col-md-12 col-sm-12 col-xs-12   mega-menu2">
                          <div className="row">
                            {this.state.Categories.map(cat => {
                              return (
                                <div className="col-md-3 col-xl-3  col-sm-4 sub-menu mb-0">
                                  <div className="CategoryThumb">
                                    <h4 className="mb-2">
                                      <a
                                        className="news-title font-weight-bold pl-0"
                                        href={"/Categories/" + cat.machine_name}
                                        params={cat.machine_name}
                                      >
                                        <img
                                          alt="fggf"
                                          src="/images/mask.png"
                                        />{" "}
                                        {cat.name}
                                      </a>
                                    </h4>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </li>
                  </li>

                  <li className="menuDesktopCategoriesBG">
                    <a href="/About">{this.state.i18n.About}</a>
                  </li>
                  <li className="menuDesktopCategoriesBG">
                    <a href="/PaymentWays">{this.state.i18n.PaymentPay}</a>
                  </li>
                  <li className="menuDesktopCategoriesBG">
                    <a href="/Delivery">{this.state.i18n.DeliveryMenu}</a>
                  </li>
                  <li className="menuDesktopCategoriesBG">
                    <a href="/CancelPolicy">{this.state.i18n.cancelPolicy}</a>
                  </li>
                  <li className="menuDesktopCategoriesBG">
                    <a href="/Returns">{this.state.i18n.returnPolicy}</a>
                  </li>
                  
                  
                  <li className="menuDesktopCategoriesBG">
                    <a href="/FAQ">{this.state.i18n.FAQ}</a>
                  </li>
                  <li className="menuDesktopCategoriesBG">
                    <a href="/Contact">{this.state.i18n.ContactUs}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Mobile Menu */}

        <div className={this.state.language == "english" ? "container-fluid row no_margin enBlockDisplay" :"container-fluid row no_margin"}>
          <div className="col-xs-12 d-block d-md-none no_pad">
            <a href="#" className="closeMenu">
              <div className="col-xs-12 closeMenuView">
                <div className="row">
                  <div className="col-xs-6 closeMenuViewRight">
                    <h1>{this.state.i18n.menu}</h1>
                  </div>
                  <div className="col-xs-6 closeMenuViewLeft">
                    <i className="fas fa-bars" />
                  </div>
                </div>
              </div>
            </a>
            <nav id="main-nav" className="mp-menu">
              <div className="mp-level">
                <h2 className="icon icon-world">القائمة</h2>
                <ul>
                  <li className="icon icon-arrow-left">
                    <a className="icon icon-display" href="#">
                      {this.state.i18n.Categories}
                    </a>
                    <div className="mp-level">
                      <ul>
                        {this.state.Categories.map(cat => {
                          return (
                            <li>
                              <a
                                href={"/Categories/" + cat.machine_name}
                                params={cat.machine_name}
                              >
                                {cat.name}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </li>

                  <li>
                    <a className="icon icon-photo" href="/About">
                      {this.state.i18n.About}
                    </a>
                  </li>
                  <li>
                    <a className="icon icon-wallet" href="/PaymentWays">
                      {this.state.i18n.PaymentPay}
                    </a>
                  </li>
                  <li>
                    <a className="icon icon-wallet" href="/Delivery">
                      {this.state.i18n.DeliveryMenu}
                    </a>
                  </li>
                  <li>
                    <a className="icon icon-wallet" href="/CancelPolicy">{this.state.i18n.cancelPolicy}</a>
                  </li>
                  <li>
                    <a className="icon icon-wallet" href="/Returns">
                      {this.state.i18n.returnPolicy}
                    </a>
                  </li>
                  <li>
                    <a className="icon icon-wallet" href="/FAQ">{this.state.i18n.FAQ}</a>
                  </li>
                  
                  
                  
                  <li>
                    <a className="icon icon-wallet" href="/Contact">
                      {this.state.i18n.ContactUs}
                    </a>
                  </li>
                  {this.state.user_id ? (
                          <li>
                            <a className="icon icon-wallet" href="/Profile">{this.state.i18n.MyAccount}</a>
                          </li>
                        ) : (
                          <li>
                            <a className="icon icon-wallet" href="/Login">
                              {this.state.i18n.LoginHead}
                              <span className="topLoginSeparator">/</span>
                              {this.state.i18n.Signup}
                            </a>
                          </li>
                        )}
                  <li className="icon icon-arrow-left">
                    <a className="icon icon-news" href="#">
                      {this.state.i18n.more}
                    </a>
                    <div className="mp-level">
                      <ul>
                        {this.state.language != "english" ? (
                          <li>
                            <a
                              onClick={() => {
                                this.setState({ isPageLoad: true }, () => {
                                  this.ChangeLanguage();
                                });
                              }}
                            >
                              English
                            </a>
                          </li>
                        ) : (
                          <li>
                            <a
                              onClick={() => {
                                this.setState({ isPageLoad: true }, () => {
                                  this.ChangeLanguage();
                                });
                              }}
                            >
                              عربي
                            </a>
                          </li>
                        )}

                        

                        {this.state.user_id ? (
                          <li>
                            <a href="/Cart">{this.state.i18n.MyCart}</a>
                          </li>
                        ) : null}

                        <li className="icon icon-arrow-left">
                          <a className="icon icon-camera" href="#">
                            {this.state.currency}
                          </a>
                          <div className="mp-level">
                            <ul>
                              {this.state.Currencies.map(Curr => {
                                return (
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="#"
                                      key={Curr.id}
                                      onClick={() => {
                                        this.setState(
                                          { isPageLoad: true },
                                          () => {
                                            this.CurrencyChange(Curr.reference);
                                          }
                                        );
                                      }}
                                    >
                                      {Curr.reference}
                                    </a>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
          
          <div className="col-md-12 col-sm-12 col-xs-12 menuDesktopLeft d-block d-md-none mobileTopSearch">
            <div className="row">
            <div className="form-inline">
              <input
                className="form-control mr-sm-1"
                type="search"
                placeholder={this.state.i18n.SearchIn}
                aria-label="Search"
                name="key"
                onChange={key => {
                  this.setState({ Key: key.target.value });
                }}
              />
              <button
                className="btn btn-outline-success my-2 my-sm-0"
                type="submit"
                onClick={() => {
                  this.Search();
                }}
              >
                {this.state.i18n.Search}
              </button>
            </div>
            </div>
          </div>
        </div>
      
      </div>
    );
  }
}
