import React, { Component } from "react";
import "react-input-range/lib/css/index.css";
import "pretty-checkbox/src/pretty-checkbox.scss";
import Global from "../Global.json";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPageLoad: false,
      value: { min: 2, max: 750 },
      showModal: false,
      quantity: 1,
      show: true,
      max: 5,
      min: 0,
      guest_id: localStorage.getItem("guest_id")
        ? localStorage.getItem("guest_id")
        : 0,
      user_id: localStorage.getItem("user_id")
        ? localStorage.getItem("user_id")
        : 0,
      CountriesAndCities: [],
      isCountrySelected: false,
      selectedCountry: 0,
      AllCity: [],
      language: localStorage.getItem("language")
        ? localStorage.getItem("language")
        : "arabic",
      Password: "12345",
      PasswordCheck: "12345",
    };
  }

  componentWillMount() {
    if (this.state.language == "arabic") {
      this.setState({
        i18n: Global.ar,
      });
    } else {
      this.setState({
        i18n: Global.en,
      });
    }

    fetch(Global.api_link + "getCountriesAndCities", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          CountriesAndCities: result.data,
          CountryID: result.data.country_id,
        });
      });
  }

  getAllCities(ContryID) {
    this.state.CountriesAndCities.map((city) => {
      if (city.country_id == ContryID) {
        this.setState({
          AllCity: city.cities,
        });
      }
    });
  }

  SignUp() {
    if (
      this.state.building_no &&
      this.state.FirstName &&
      this.state.LastName &&
      this.state.Phone &&
      this.state.AdditionalPhone &&
      this.state.Country &&
      this.state.City &&
      this.state.Address &&
      this.state.EmailSignUp &&
      this.state.Password &&
      this.state.PasswordCheck &&
      this.state.Street != null
    ) {
      if (
        this.state.Phone.length > 9 &&
        this.state.AdditionalPhone.length > 9
      ) {
        if (this.state.Password === this.state.PasswordCheck) {
          let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
          if (reg.test(this.state.EmailSignUp) === false) {
            alert(this.state.i18n.emailValidationSyntax);
          } else {
            fetch(Global.api_link + "userSignup", {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                token: Global.token,
                email: this.state.EmailSignUp,
                password: this.state.Password,
                name: this.state.FirstName + " " + this.state.LastName,
                phone1: this.state.Phone,
                phone2: this.state.AdditionalPhone,
                country: this.state.Country,
                city: this.state.City,
                area: this.state.Address,
                street: this.state.Street,
                guest_id: this.state.guest_id,
                building_no: this.state.building_no
              }),
            })
              .then((response) => response.json())
              .then((result) => {
                if (result.status == 1) {
                  localStorage.setItem("user_id", result.data);
                  window.location.href = "/";
                } else if (result.status == 2) {
                  alert(this.state.i18n.emailValidationEmailFound);
                }
                //console.log(this.state.user_id)
              });
          }
        } else {
          alert(this.state.i18n.passwordsValidationMatch);
        }
      } else {
        alert(this.state.i18n.phonesValidation);
      }
    } else {
      alert(this.state.i18n.Required);
    }
  }

  Login() {
    if (this.state.Email && this.state.PasswordLogin != null) {
      fetch(Global.api_link + "userLogin", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: Global.token,
          email: this.state.Email,
          password: this.state.PasswordLogin,
          guest_id: this.state.guest_id,
        }),
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.data) {
            localStorage.setItem("user_id", result.data);
            window.location.href = "/";
          } else {
            alert("Email Or Password Wrong");
          }
          //console.log(this.state.user_id)
        });
    } else {
      alert(this.state.i18n.Required);
    }
  }

  render() {
    if (this.state.isPageLoad) {
      return (
        <div>
          <div className="container22">
            <div className="Spinner">
              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>

              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>

              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          {" "}
          {/** Categories Start  */}
          <div className="container">
            {" "}
            {/** All Categories Start  */}
            <div className="row">
              {" "}
              {/** Row */}
              <div className="col-md-12 col-sm-12 col-xs-12 AllCatego AllCategoProduct">
                {" "}
                {/** Categories Start */}
                <h1>
                  {" "}
                  {this.state.i18n.Login} / {this.state.i18n.Signup}
                </h1>
                <div className="col-md-12 col-sm-12 col-xs-12 CartTable no_pad_xs">
                  <ul className="nav nav-tabs">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        data-toggle="tab"
                        href="#menu1"
                      >
                        {this.state.i18n.Login}
                      </a>
                    </li>

                    <li className="nav-item">
                      <a className="nav-link " data-toggle="tab" href="#home">
                        {this.state.i18n.Signup}
                      </a>
                    </li>

                    <li className="nav-item">
                      <a className="nav-link " data-toggle="tab" href="#home2">
                        {this.state.i18n.forgotPassword}
                      </a>
                    </li>
                  </ul>

                  <div className="tab-content">
                    <div
                      className="tab-pane container fade checkGuestForms"
                      id="home"
                    >
                      <div id="basic">
                        {" "}
                        {/** Signup Form Start  */}
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 no_pad">
                          <div class="input-container">
                            <i class="fa fa-user icon"></i>
                            <input
                              class="input-field"
                              type="text"
                              placeholder={this.state.i18n.FirstName}
                              name="firstname"
                              onChange={(firstname) =>
                                this.setState({
                                  FirstName: firstname.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 no_pad">
                          <div class="input-container">
                            <i class="fa fa-user icon"></i>
                            <input
                              class="input-field"
                              type="text"
                              placeholder={this.state.i18n.LastName}
                              name="lastname"
                              onChange={(lastname) =>
                                this.setState({
                                  LastName: lastname.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 no_pad">
                          <div class="input-container">
                            <i class="fa fa-phone icon"></i>
                            <input
                              class="input-field"
                              type="text"
                              placeholder={this.state.i18n.Phone}
                              name="phone"
                              onChange={(phone) =>
                                this.setState({ Phone: phone.target.value })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 no_pad">
                          <div class="input-container">
                            <i class="fa fa-phone icon"></i>
                            <input
                              class="input-field"
                              type="text"
                              placeholder={this.state.i18n.Phone2}
                              name="phone2"
                              onChange={(phone2) =>
                                this.setState({
                                  AdditionalPhone: phone2.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 no_pad">
                          <div class="input-container">
                            <i class="fa fa-globe icon"></i>
                            <select
                              class="input-field"
                              name="country"
                              onChange={(e) => {
                                this.setState(
                                  {
                                    selectedCountry: e.target.value,
                                    isCountrySelected: true,
                                    Country: e.target.value,
                                  },
                                  () => {
                                    this.getAllCities(
                                      this.state.selectedCountry
                                    );
                                  }
                                );
                              }}
                            >
                              <option disabled selected>
                                {this.state.i18n.Country}
                              </option>
                              {this.state.CountriesAndCities.map((City) => {
                                return (
                                  <option
                                    key={City.country_id}
                                    value={City.country_id}
                                  >
                                    {City.country_name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 no_pad">
                          <div class="input-container">
                            <i class="fa fa-globe icon"></i>
                            {this.state.isCountrySelected &&
                            this.state.selectedCountry ? (
                              <select
                                class="input-field"
                                name="city"
                                onChange={(city) =>
                                  this.setState({ City: city.target.value })
                                }
                              >
                                <option disabled selected>
                                  {this.state.i18n.City}
                                </option>
                                {this.state.AllCity.map((City) => {
                                  return (
                                    <option
                                      key={City.city_id}
                                      value={City.city_id}
                                    >
                                      {City.city_name}
                                    </option>
                                  );
                                })}
                              </select>
                            ) : (
                              <select
                                class="input-field"
                                name="city"
                                onChange={(city) =>
                                  this.setState({ City: city.target.value })
                                }
                              >
                                <option disabled selected>
                                  {this.state.i18n.City}
                                </option>
                              </select>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 no_pad">
                          <div class="input-container">
                            <i class="fa fa-envelope icon"></i>
                            <input
                              class="input-field"
                              type="email"
                              placeholder={this.state.i18n.Email}
                              name="email"
                              onChange={(email) =>
                                this.setState({
                                  EmailSignUp: email.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 no_pad">
                          <div class="input-container">
                            <i class="fa fa-home icon"></i>
                            <input
                              class="input-field"
                              type="text"
                              placeholder={this.state.i18n.Address}
                              name="address"
                              onChange={(address) =>
                                this.setState({ Address: address.target.value })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 no_pad">
                          <div class="input-container">
                            <i class="fa fa-road icon"></i>
                            <input
                              class="input-field"
                              type="text"
                              placeholder={this.state.i18n.Street}
                              name="street"
                              onChange={(street) =>
                                this.setState({ Street: street.target.value })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 no_pad">
                          <div class="input-container">
                            <i class="fa fa-road icon"></i>
                            <input
                              class="input-field"
                              type="text"
                              placeholder={this.state.i18n.building_no}
                              name="building_no"
                              onChange={(street) =>
                                this.setState({
                                  building_no: street.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 CartbuttContainer passCreateClass">
                          <p
                            style={{
                              textAlign: "start",
                              color: "#ca8811",
                              fontWeight: "bold",
                            }}
                          >
                            {this.state.i18n.signupPassCreate}
                          </p>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 CartbuttContainer  ">
                          <a onClick={() => this.SignUp()} className="Cartbutt">
                            {" "}
                            {this.state.i18n.Signup}
                          </a>
                        </div>
                      </div>{" "}
                      {/** Signup Form End  */}
                    </div>

                    <div
                      className="tab-pane container active checkGuestForms"
                      id="menu1"
                    >
                      <div id="basic">
                        {" "}
                        {/** Login Form Start  */}
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 no_pad">
                          <div class="input-container">
                            <i class="fa fa-envelope icon"></i>
                            <input
                              class="input-field"
                              type="email"
                              placeholder={this.state.i18n.Email}
                              name="emailLogin"
                              onChange={(emailLogin) =>
                                this.setState({
                                  Email: emailLogin.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 no_pad">
                          <div class="input-container">
                            <i class="fa fa-key icon"></i>
                            <input
                              class="input-field"
                              type="password"
                              placeholder={this.state.i18n.Password}
                              name="passwordLogin"
                              onChange={(passwordLogin) =>
                                this.setState({
                                  PasswordLogin: passwordLogin.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 CartbuttContainer">
                          <a onClick={() => this.Login()} className="Cartbutt">
                            {" "}
                            {this.state.i18n.Login}
                          </a>
                        </div>
                      </div>{" "}
                      {/** Login Form End  */}
                    </div>

                    <div
                      className="tab-pane container checkGuestForms"
                      id="home2"
                    >
                      <div id="basic">
                        {" "}
                        {/** Login Form Start  */}
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 no_pad">
                          <div class="input-container">
                            <i class="fa fa-envelope icon"></i>
                            <input
                              class="input-field"
                              type="email"
                              placeholder={this.state.i18n.Email}
                              name="emailLogin"
                              onChange={(emailReset) =>
                                this.setState({
                                  emailReset: emailReset.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 CartbuttContainer">
                          <a
                            onClick={() => {
                              if (this.state.emailReset) {
                                this.setState(
                                  {
                                    isPageLoad: true,
                                  },
                                  () => {
                                    this.forgotPassword();
                                  }
                                );
                              } else {
                                alert(this.state.i18n.Required);
                              }
                            }}
                            className="Cartbutt"
                          >
                            {" "}
                            {this.state.i18n.Reset}
                          </a>
                        </div>
                      </div>{" "}
                      {/** Login Form End  */}
                    </div>
                  </div>
                </div>
              </div>{" "}
              {/**Categories  end*/}
            </div>{" "}
            {/** Row End*/}
          </div>
        </div>
      );
    }
  }

  forgotPassword() {
    fetch(Global.api_link + "resetPassword", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        email: this.state.emailReset,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        let data = result.data;
        this.setState(
          {
            isPageLoad: false,
          },
          () => {
            if (data) {
              alert(this.state.i18n.signupPassReCreate);
            } else {
              alert(this.state.i18n.signupPassReCreateNoEmail);
            }
          }
        );
      });
  }
}
