
import React, { Component } from "react";  
import "react-input-range/lib/css/index.css" ; 
import 'pretty-checkbox/src/pretty-checkbox.scss';  
import Global from "../Global.json";
export default class Failed extends Component {
    
  constructor(props) {
    super(props);

    this.state = {
      isPageLoad: true,
      value: { min: 2, max: 750 },
      showModal: false,
      quantity: 1,
      show: true,
      max: 5,
      min: 0,
      user_id: localStorage.getItem('user_id') ? localStorage.getItem('user_id') : 0,
      language: localStorage.getItem('language') ? localStorage.getItem('language') : "arabic",
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    
     
  }
 
  componentWillMount() { 
     
    if(this.state.language == "arabic"){
      this.setState({
          i18n:Global.ar,
          isPageLoad: false
      });
    }else {
        this.setState({
            i18n:Global.en,
            isPageLoad: false
        });
    }
 
  }
 
handleOpenModal () {
    this.setState({ showModal: true });
}
  
handleCloseModal () {
    this.setState({ showModal: false });
}
   
render() {

    if (this.state.isPageLoad) {

      return <div>
          <div className="container22">
            <div  className="Spinner">
              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>  
              </div>

              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>  
              </div>

              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>  
              </div>
            </div>
          </div>
      </div>;
  }else { 
    return (

    <div> {/** Categories Start  */}

      <div className="container"> {/** All Categories Start  */}
        <div className="row"> {/** Row */}
          <div className ="col-md-12 col-sm-12 col-xs-12 AllCatego AllCategoProduct"> {/** Categories Start */}
          <h1>{this.state.i18n.Failed}</h1> 
            <div className="col-md-12 col-sm-12 col-xs-12 CartTable Paymentmethod SuccessContainer">
                 
                <p className="MessageSuccess">{this.state.i18n.MessageFailed}</p>
                <a href="/Cart" > {this.state.i18n.backToCart} </a>
            </div> 
           </div> {/**Categories  end*/}
        </div> {/** Row End*/}
      </div> 
    </div>

    );
  }
}
}
