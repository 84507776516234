import React, { Component } from "react";
import Slider from "react-slick";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import Global from "../Global.json";

export default class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
      Tastimonials: [],
      isPageLoad: true,
      language:
        localStorage.getItem("language") != null
          ? localStorage.getItem("language")
          : "arabic"
    };
  }

  componentWillMount() {
    if (this.state.language == "arabic") {
      this.setState({
        i18n: Global.ar
      });
    } else {
      this.setState({
        i18n: Global.en
      });
    }
    fetch(Global.api_link + "getSocialMediaLinks", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: Global.token
      })
    })
      .then(response => response.json())
      .then(result => {
        this.setState(
          {
            Social: result.data
          },
          () => {
            this.getPhoneAEmail();
          }
        );
      });
  }

  getPhoneAEmail() {
    fetch(Global.api_link + "getContactPageDetails", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language
      })
    })
      .then(response => response.json())
      .then(result => {
        this.setState(
          {
            contactDet: result.data
          },
          () => {
            this.getTasts();
          }
        );
      });
  }

  getTasts() {
    {
      /** Tastimonials */
    }
    fetch(Global.api_link + "getTastimonials", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language
      })
    })
      .then(response => response.json())
      .then(result => {
        this.setState(
          {
            Tastimonials: result.data,
            isPageLoad: false
          },
          () => {}
        );
      });
  }

  addNewsletterEmail() {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (this.state.Email != null && reg.test(this.state.Email) === true) {
      fetch(Global.api_link + "addNewsletterEmail", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          token: Global.token,
          email: this.state.Email
        })
      })
        .then(response => response.json())
        .then(result => {
          this.setState({
            isPageLoad: false
          });
          alert("Thank You");
        });
    } else {
      alert(this.state.i18n.EmailReq);
      this.setState({
        isPageLoad: false
      });
    }
  }

  render() {
    const { photoIndex, isOpen } = this.state;
    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
            //dots: true,
            //arrows : false
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    const images = [
      "images/1.png",
      "images/2016-09-30-PHOTO-00000470.png",
      "images/2016-09-30-PHOTO-00000470.png",
      "images/2016-09-30-PHOTO-00000470.png"
    ];

    if (this.state.isPageLoad) {
      return (
        <div>
          <div className="container22">
            <div className="Spinner">
              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>

              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>

              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="MostSold">
            {" "}
            {/** Clients Experience Start */}
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <h1>{this.state.i18n.Tastimonials}</h1>
                  <Slider {...settings}>
                    {this.state.Tastimonials.map((tast, index) => {
                      return (
                        <div className="item">
                          <div className="col-sm-12 col-md-12 col-xs-12">
                            <div className="Product">
                              <div className="thumbnail">
                                <img
                                  src={Global.image_link + tast}
                                  onClick={() =>
                                    this.setState({
                                      isOpen: true,
                                      photoIndex: index
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                  {isOpen && (
                    <Lightbox
                      mainSrc={
                        Global.image_link + this.state.Tastimonials[photoIndex]
                      }
                      nextSrc={
                        Global.image_link +
                        this.state.Tastimonials[
                          (photoIndex + 1) % this.state.Tastimonials.length
                        ]
                      }
                      prevSrc={
                        Global.image_link +
                        this.state.Tastimonials[
                          (photoIndex + this.state.Tastimonials.length - 1) %
                            this.state.Tastimonials.length
                        ]
                      }
                      onCloseRequest={() => this.setState({ isOpen: false })}
                      onMovePrevRequest={() =>
                        this.setState({
                          photoIndex:
                            (photoIndex + this.state.Tastimonials.length - 1) %
                            this.state.Tastimonials.length
                        })
                      }
                      onMoveNextRequest={() =>
                        this.setState({
                          photoIndex:
                            (photoIndex + 1) % this.state.Tastimonials.length
                        })
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>{" "}
          {/** Clients Experience End */}
          <div className="whatsappDiv">
            <a href="https://wa.me/966501408646" target="_balnk">
              <img src="/images/whatsapp.png" />
            </a>
          </div>
          <div className="container">
            <div className="col-md-12 col-sm-12 col-xs-12 footer">
              <div className="row">
                <div className="col-md-6 col-sm-12 d-none d-md-block footerRight">
                  <ul>
                    <li>
                      <a href={"mailto:" + this.state.contactDet.main_email}>
                        <i className="fa fa-envelope" />{" "}
                        {this.state.contactDet.main_email}
                      </a>
                    </li>
                    <li>
                      <a href={"tel:" + this.state.contactDet.main_phone}>
                        <i className="fa fa-phone" />{" "}
                        {this.state.contactDet.main_phone}
                      </a>
                    </li>
                    <li>
                      <iframe
                        src="https://maroof.sa/Business/getStamp?bid=63818"
                        className="iframeDc"
                        id="iframeDc"
                        frameBorder="0"
                      />
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 col-sm-12 col-xs-12 footerLeft">
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <h1>{this.state.i18n.emailList}</h1>
                    <h5>{this.state.i18n.emailText}</h5>
                  </div>

                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <div className="form-inline ">
                      <input
                        className="form-control mr-sm-1 col-md-8 col-sm-8"
                        type="search"
                        name="email"
                        placeholder={this.state.i18n.Email}
                        aria-label="Search"
                        onChange={email =>
                          this.setState({ Email: email.target.value })
                        }
                      />
                      <button
                        className="btn btn-outline-success my-2 my-sm-0 col-md-3 col-sm-3"
                        type="submit"
                        onClick={() => {
                          this.setState({ isPageLoad: true }, () => {
                            this.addNewsletterEmail();
                          });
                        }}
                      >
                        {this.state.i18n.Send}
                      </button>
                    </div>
                  </div>
                  <div className="col-md-12 col-sm-12 col-xs-12 creditLogss">
                    {
                      this.state.language == "arabic" ? 
<img src="/images/creds2.png" />
                      :
<img src="/images/creds.png" />
                    }
                    
                  </div>
                </div>
                <div className="col-md-6 col-sm-12 d-block d-md-none footerRight">
                  <ul>
                    <li>
                      <a href={"mailto:" + this.state.contactDet.main_email}>
                        <i className="fa fa-envelope" />{" "}
                        {this.state.contactDet.main_email}
                      </a>
                    </li>
                    <li>
                      <a href={"tel:" + this.state.contactDet.main_phone}>
                        <i className="fa fa-phone" />{" "}
                        {this.state.contactDet.main_phone}
                      </a>
                    </li>
                    <li>
                      <iframe
                        src="https://maroof.sa/Business/getStamp?bid=63818"
                        className="iframeDc"
                        id="iframeDc"
                        frameBorder="0"
                      />
                    </li>
                  </ul>
                </div>
              </div>
              <div className=" col-xs-12 medias">
                <ul>
                  <li>
                    <a href={this.state.Social.facebook}>
                      {" "}
                      <img src="/images/facebook.png" />
                    </a>
                  </li>
                  <li>
                    <a href={this.state.Social.twitter}>
                      {" "}
                      <img src="/images/twitter.png" />
                    </a>
                  </li>
                  <li>
                    <a href={this.state.Social.instegram}>
                      {" "}
                      <img src="/images/instagram.png" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-12 col-sm-12 col-xs-12 footerBottom">
                <h3>
                  <i className="fa fa-copyright"></i>{" "}
                  {this.state.i18n.PolicyFooter}
                </h3>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
