
import React, { Component } from "react";    
import Global from "../Global.json";

export default class Deleviry extends Component {
    
  constructor(props) {
    super(props);

    this.state = {
      isPageLoad: true,
      DeliveryText : "",
      CountriesAndCities : [],
      AllCity : [],
      currency: localStorage.getItem("currency"),
      language: localStorage.getItem("language")
        ? localStorage.getItem("language")
        : "arabic",
    };

  }


  componentWillMount(){ 
    if (this.state.language == "arabic") {
      this.setState({
        i18n: Global.ar
      });
    } else {
      this.setState({
        i18n: Global.en
      });
    }

    fetch(Global.api_link + "getDeliveryPageText", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language
      })
    })
      .then(response => response.json())
      .then(result => {
        this.setState({
          DeliveryText: result.data[0].text,
          isPageLoad: false
        });
      }).catch(error => {
        console.error(error);
      });


      fetch(Global.api_link + 'getCountriesAndCities', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token : Global.token,
          lang : this.state.language
        }),
      }).then((response) => response.json())
        .then(
            (result) => {   
               this.setState({
                CountriesAndCities : result.data, 
                CountryID : result.data.country_id
               })
              }
        )
        
        fetch(Global.api_link + "getCurrencyConvertor", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            token: Global.token,
            currency: this.state.currency
          })
        })
          .then(response => response.json())
          .then(result => {
            this.setState({
              currencyConverter: result.data
            });
          });


  }
   
  getAllCities(ContryID){ 
    this.state.CountriesAndCities.map(city=>{
      if(city.country_id == ContryID) {
       this.setState({
         AllCity : city.cities
       })
      }
    }) 
 }

  MyComponent() {
    return <div dangerouslySetInnerHTML={this.createMarkup()} />;
  }

  createMarkup() {
    return { __html: this.state.DeliveryText };
  }

  DeliveryCost() {
    fetch(Global.api_link + "getDeliveryTimeCost", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language,
        city_id : this.state.City
      })
    })
      .then(response => response.json())
      .then(result => {
        this.setState({
          duration_time: result.data.duration_time,
          isPageLoad: false,
          price : result.data.price
        });
      }).catch(error => {
        console.error(error);
      });

  }

  render() { 
    if (this.state.isPageLoad) {

      return <div>
          <div className="container22">
            <div  className="Spinner">
              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>  
              </div>

              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>  
              </div>

              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>  
              </div>
            </div>
          </div>
      </div>;
  }else { 
    return (

    <div> {/** Categories Start  */}

      <div className="container"> {/** All Categories Start  */}
        <div className="row"> {/** Row */}
          <div className ="col-md-12 col-sm-12 col-xs-12 AllCatego"> {/** Categories Start */}
          <h1> {this.state.i18n.Delivary} </h1> 
            
            <div  className="tab-content Deleviry" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                {this.MyComponent()}
            </div>
        </div>
          </div> {/**Categories  end*/}
        </div> {/** Row End*/}
      </div>

      <div className="container">
          <div className="row"> 
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  AllCatego">
                <h1> {this.state.i18n.DelivaryCountry}  </h1>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 City">
                    <div className ="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <div class="input-container"> 
                          <select class="input-field" name="country" onChange={(e)=>{ 
                                        this.setState({ selectedCountry : e.target.value,isCountrySelected:true , Country : e.target.value},()=>{
                                          this.getAllCities(this.state.selectedCountry);
                                        });
                                        }}>
                                <option disabled selected>{this.state.i18n.Country}</option>
                                {
                                  this.state.CountriesAndCities.map(City => {
                                    return(
                                      <option key={City.country_id} value={City.country_id} selected={this.state.Country ==City.country_id ? 'selected' : null }>{City.country_name}</option>
                                    );
                                  })  
                                 
                                } 
                          </select>
                        </div>
                    </div>
                    <div className ="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <div class="input-container">
                          {
                              this.state.isCountrySelected && this.state.selectedCountry?
                              <select class="input-field" name="city" onChange= 
                              {(city) => {
                                this.setState({ isPageLoad:true , City : city.target.value}, () => {
                            
                                  this.DeliveryCost()
                                });
                              }}>
                                <option disabled selected>{this.state.i18n.City}</option>
                                {
                                  this.state.AllCity.map(City => {
                                    return(
                                      <option key={City.city_id}  value={City.city_id}  selected={this.state.City ==City.city_id ? 'selected' : null }>{City.city_name}</option>
                                    );
                                  })  
                                } 
                              </select>
                              :
                              <select class="input-field" name="city" onChange={(city) => this.setState({City : city.target.value})}>
                                <option disabled selected>{this.state.i18n.City}</option>
                              </select>
                          }
                        </div>
                    </div>
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 deleviryCalc">
                    <div className ="col-lg-6 col-md-6 col-sm-6 col-xs-12 borderDeleviry">
                        <img src="images/delivery-truck.png" className="tab_icon" /> 
                        <h2>{this.state.i18n.DelivaryTime}</h2>
                        <h3>{this.state.duration_time ? this.state.duration_time : null}</h3>
                    </div>
                    <div className ="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <img src="images/priceq.png" className="tab_icon" /> 
                        <h2>{this.state.i18n.DelivaryPrice}</h2>
                         
                        <h3>{this.state.price ?  parseFloat((this.state.price) * parseFloat(this.state.currencyConverter)).toFixed(1) : null} {this.state.price ? this.state.currency :null}</h3>
                    </div>
                </div>


            </div>
          </div>
       
     </div>
 
    </div>

    );
  }
}
}
