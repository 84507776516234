import React, { Component } from "react";
import Global from "../Global.json";
import LinesEllipsis from "react-lines-ellipsis";

export default class Categories extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPageLoad: true,
      CatName: props.match.params.CatName,
      Categories: [],
      KeySearch: null,
      language: localStorage.getItem("language")
        ? localStorage.getItem("language")
        : "arabic"
    };
  }

  componentWillMount() {
    if (this.state.language == "arabic") {
      this.setState({
        i18n: Global.ar
      });
    } else {
      this.setState({
        i18n: Global.en
      });
    }
  }

  componentDidMount() {
    {
      /** Get Category Data, Childs Or Products */
    }
    fetch(Global.api_link + "getCategoryChildsOrProducts", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language,
        cat_machine_name: this.state.CatName
      })
    })
      .then(response => response.json())
      .then(result => {
        if (result.hasChilds == 1) {
          this.setState({
            Categories: result.data,
            isPageLoad: false,
            category_name: result.category_name
          });
        } else {
          window.location.href = "/CategoryProduct/" + this.state.CatName;
        }
      });
  }

  render() {
    if (this.state.isPageLoad) {
      return (
        <div>
          <div className="container22">
            <div className="Spinner">
              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>

              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>

              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          {" "}
          {/** Categories Start  */}
          <div className="container">
            {" "}
            {/** All Categories Start  */}
            <div className="row">
              {" "}
              {/** Row */}
              <div className="col-md-12 col-sm-12 col-xs-12 AllCatego CatSearch no_pad">
              <div className="row no_margin">
                {" "}
                {/** Categories Start */}
                <h1>{this.state.category_name}</h1>
                <div className="ReadyProduct d-none d-md-block">
                  <button
                    className="input-group-text ButtonSearch"
                    onClick={() => {
                      window.location.href =
                        "/CategoryProduct/" + this.state.CatName + "___all";
                    }}
                    style={{ top: -10 }}
                  >
                    {this.state.i18n.SeeAll}
                  </button>
                </div>
                <div className="d-block d-md-none col-xs-12 displayEnglishCenters">
                  {" "}
                  {/** Category Thumb Start */}
                  <a
                    href={"/CategoryProduct/" + this.state.CatName + "___all"}
                    className=""
                    params={this.state.CatName + "___all"}
                  >
                    <div
                      className="Category"
                      style={{ padding: 10, border: "3px solid #dfb871" }}
                    >
                      <div className="caption">
                        <h3 style={{ marginTop: 0 }}>
                          {this.state.i18n.SeeAll}
                        </h3>
                      </div>
                    </div>
                  </a>
                </div>
                {/** Category Thumb End */}
                <div className="row" style={{ margin: -1,width:"100%" }}>
                  {this.state.Categories.map(Cat => {
                    return (
                      <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6">
                        {" "}
                        {/** Category Thumb Start */}
                        <a
                          href={"/CategoryProduct/" + Cat.machine_name}
                          className=""
                          params={Cat.machine_name}
                        >
                          <div className="Category">
                            <div className="thumbnail">
                              {Cat.image_thumb ? (
                                <img
                                  src={Global.image_link + Cat.image_thumb}
                                  alt={Cat.alternative_name}
                                />
                              ) : (
                                <img
                                  src="/images/default-no-image.png"
                                  alt={Cat.alternative_name}
                                />
                              )}
                            </div>
                            <div className="caption">
                              <h3 style={{ minHeight: 50 }}>
                                {Cat.name
                                  ? Cat.name
                                  : Cat.alternative_name}
                              </h3>
                            </div>
                          </div>
                        </a>
                      </div>
                    );
                  })}
                </div>
                {/** Category Thumb End */}
                </div>
              </div>{" "}
              {/**Categories  end*/}
            </div>{" "}
            {/** Row End*/}
          </div>
        </div>
      );
    }
  }
}
