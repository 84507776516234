import React, { Component } from "react";
import "react-input-range/lib/css/index.css";
import "pretty-checkbox/src/pretty-checkbox.scss";
import Global from "../Global.json";

export default class CheckoutStock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPageLoad: true,
      value: { min: 2, max: 750 },
      showModal: false,
      quantity: 1,
      show: true,
      guest_id: localStorage.getItem("guest_id")
        ? localStorage.getItem("guest_id")
        : 0,
      user_id: localStorage.getItem("user_id")
        ? localStorage.getItem("user_id")
        : 0,
      Pricing: [],
      currency: localStorage.getItem("currency"),
      Payment: 0,
      CartInstock: [],
      Copoun: 0,
      language: localStorage.getItem("language")
        ? localStorage.getItem("language")
        : "arabic",
      isBuild: false,
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  componentDidMount() {
    if (this.state.language == "arabic") {
      this.setState({
        i18n: Global.ar,
      });
    } else {
      this.setState({
        i18n: Global.en,
      });
    }

    if (this.state.guest_id == 0) {
      fetch(Global.api_link + "generateGuestID", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: Global.token,
        }),
      })
        .then((response) => response.json())
        .then((result) => {
          localStorage.setItem("guest_id", result.data);
        });
    }

    {
      /** Currency Converter */
    }

    fetch(Global.api_link + "getCurrencyConvertor", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        currency: this.state.currency,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          currencyConverter: result.data,
        });
        this.getUserProfile();
      });

    //this.getUserCheckoutInstockProducts();
  }

  getUserProfile() {
    fetch(Global.api_link + "getUserProfile", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        user_id: this.state.user_id,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        let data = result.data[0];

        if (data.building_no != "" && data.building_no != null) {
          this.setState({
            isBuild: true,
          });
        }
        this.setState({
          CountryUser: data.country,
          CityUser: data.city,
          building_no: data.building_no,
        });
        this.getUserCartInstockProducts();

        //console.log(data);
      });
  }

  getUserCartInstockProducts = () => {
    fetch(Global.api_link + "getUserCheckoutProducts", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        user_id: this.state.user_id,
        lang: this.state.language,
        status: 0,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        if (parseInt(result.data.length) > 0) {
          this.setState({
            CartInstock: result.data,
            isPageLoad: false,
          });
        } else {
          window.location.href = "/Cart";
        }
      });
  };

  getUserCheckoutInstockProducts() {
    /**  User Checkout Instock Products  */
    fetch(Global.api_link + "getUserCheckoutInstockProducts", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        user_id: this.state.user_id,
        status: 0,
        payment_method: this.state.Payment,
        coupon: this.state.Copoun ? this.state.Copoun : 999999,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          productsAmount: result.data.productsAmount,
          deliveryAmount: result.data.deliveryAmount,
          discountAmount: result.data.discountAmount,
          totalAmount: result.data.totalAmount,
          VATAmount: result.data.VATAmount,
          isPageLoad: false,
        });
      })
      .catch((error) => {
        //console.log(error);
      });
  }
  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  PaymentAction() {
    if (this.state.Check && this.state.Payment) {
      if (this.state.hear) {
        if (this.state.building_no) {
          this.setState(
            {
              isPageLoad: true,
            },
            () => {
              fetch(Global.api_link + "userUpdateProfileForBuildingNo", {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  token: Global.token,
                  building: this.state.building_no,
                  user_id: this.state.user_id,
                }),
              })
                .then((response) => response.json())
                .then((result) => {
                  fetch(Global.api_link + "createUserOrder", {
                    method: "POST",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      token: Global.token,
                      currency: this.state.currency,
                      user_id: this.state.user_id,
                      status: 0,
                      payment_method: this.state.Payment,
                      coupon: this.state.Copoun ? this.state.Copoun : 999999,
                      how_hear: this.state.hear ? this.state.hear : null,
                    }),
                  })
                    .then((response) => response.json())
                    .then((result) => {
                      //localStorage.setItem("order_id", result.data);
                      window.location.href = "/Payment/" + result.data;
                      this.setState({
                        order_id: result.data,
                      });
                    });
                })
                .catch((error) => {});
            }
          );
        } else {
          alert(this.state.i18n.buildingAlert);
        }
      } else {
        alert(this.state.i18n.howHearAlert);
      }
    } else {
      alert(this.state.i18n.AgreeAlert);
    }
  }

  render() {
    if (this.state.isPageLoad) {
      return (
        <div>
          <div className="container22">
            <div className="Spinner">
              <div className="dot-container">
                <div className="dot" />
                <div className="dot" />
                <div className="dot" />
              </div>

              <div className="dot-container">
                <div className="dot" />
                <div className="dot" />
                <div className="dot" />
              </div>

              <div className="dot-container">
                <div className="dot" />
                <div className="dot" />
                <div className="dot" />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          {" "}
          {/** Categories Start  */}
          <div className="container">
            {" "}
            {/** All Categories Start  */}
            <div className="row">
              {" "}
              {/** Row */}
              <div className="col-md-12 col-sm-12 col-xs-12 AllCatego AllCategoProduct">
                {" "}
                {/** Categories Start */}
                <h1> {this.state.i18n.Approved}</h1>
                <div className="col-md-12 col-sm-12 col-xs-12 CartTable">
                  <table className="CheckoutTabel">
                    <tr>
                      <th>#</th>
                      <th>{this.state.i18n.Name}</th>
                      <th>{this.state.i18n.Size}</th>
                      <th>{this.state.i18n.Color}</th>
                      <th>{this.state.i18n.Qty}</th>
                      <th>{this.state.i18n.PiecePrice}</th>
                    </tr>
                    {this.state.CartInstock.map((Cart) => {
                      return (
                        <tr>
                          <td>
                            <div className="CartImage">
                              <img
                                alt="Image"
                                src={Global.image_link + Cart.image}
                              />
                            </div>
                          </td>
                          <td>
                            {Cart.name ? Cart.name : Cart.alternative_name}
                          </td>
                          <td>
                            {" "}
                            {Cart.size_name ? Cart.size_name : "No Size"}{" "}
                          </td>
                          <td>
                            {" "}
                            {Cart.color_name
                              ? Cart.color_name
                              : "No Color"}{" "}
                          </td>
                          <td> {Cart.qty}</td>
                          <td>
                            {" "}
                            {Cart.sale_price != "0" && Cart.sale_price != null
                              ? parseFloat(
                                  parseFloat(Cart.sale_price) *
                                    parseFloat(this.state.currencyConverter)
                                ).toFixed(1)
                              : parseFloat(
                                  parseFloat(Cart.price) *
                                    parseFloat(this.state.currencyConverter)
                                ).toFixed(1)}{" "}
                            {this.state.currency}
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                  <div id="basic">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 cktsoutSelect">
                      <div class="input-container">
                        <img alt="payment" src="images/debit-card.png" />
                        <select
                          class="input-field"
                          onChange={(e) => {
                            this.setState({ Payment: e.target.value }, () => {
                              this.getUserCheckoutInstockProducts();
                            });
                          }}
                        >
                          <option disabled selected>
                            {this.state.i18n.PriceFor}
                          </option>
                          <option value={2}>PayPal</option>
                          <option value={3}>
                            {this.state.i18n.BankTransfer}
                          </option>
                          <option value={4}>
                            {this.state.i18n.CreditCard}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 cktsoutInput">
                      <div class="input-container ">
                        <img
                          alt="payment"
                          class="cktsoutInputIms"
                          src="images/offer.png"
                        />
                        <input
                          class="input-field"
                          type="text"
                          placeholder={this.state.i18n.CopounDiscount}
                          name="copoun"
                          onChange={(copoun) =>
                            this.setState({ Copoun: copoun.target.value })
                          }
                        />
                        <button
                          onClick={() => {
                            if (this.state.Payment) {
                              this.getUserCheckoutInstockProducts();
                            } else {
                              alert("الرجاء إختيار طريقة الدفع أولاً");
                            }
                          }}
                          className="redeemBut redmmm"
                        >
                          {this.state.i18n.applyDiscount}
                        </button>
                      </div>
                    </div>
                    {this.state.Payment ? (
                      <div>
                        <div className="col-md-12 col-sm-12 col-xs-12 TotalPriceCount">
                          <h4>
                            {this.state.i18n.DeliveryPrice}:
                            <h5>
                              {this.state.deliveryAmount
                                ? parseFloat(
                                    parseFloat(this.state.deliveryAmount) *
                                      parseFloat(this.state.currencyConverter)
                                  ).toFixed(1)
                                : null}{" "}
                              {this.state.currency}
                            </h5>
                          </h4>{" "}
                          <br />
                          <h4>
                            {this.state.i18n.Tax} :
                            <h5>
                              {this.state.VATAmount
                                ? parseFloat(
                                    parseFloat(this.state.VATAmount) *
                                      parseFloat(this.state.currencyConverter)
                                  ).toFixed(1)
                                : null}{" "}
                              {this.state.currency}
                            </h5>
                          </h4>
                          <br />
                          <h4>
                            {this.state.i18n.Discount}:
                            <h5>
                              {" "}
                              {this.state.discountAmount
                                ? parseFloat(
                                    parseFloat(this.state.discountAmount) *
                                      parseFloat(this.state.currencyConverter)
                                  ).toFixed(1)
                                : null}{" "}
                              {this.state.currency}
                            </h5>
                          </h4>{" "}
                          <br />
                          <h4>
                            {this.state.i18n.TotalAmount}:
                            <h5>
                              {this.state.totalAmount
                                ? parseFloat(
                                    parseFloat(this.state.totalAmount) *
                                      parseFloat(this.state.currencyConverter)
                                  ).toFixed(1)
                                : null}{" "}
                              {this.state.currency}
                            </h5>
                          </h4>
                          <br />
                          {/**
                        *
                        *<div className="prettyALign">
                        <div class="pretty p-icon p-round p-jelly">
                            <input type="checkbox" />
                            <div class="state p-primary">
                                <i class="icon fa fa-check"></i>
                                <label> أوافق على الشروط والأحكام</label>
                            </div>
                        </div>
                    </div>
                        */}
                          <br />
                          <br />
                          <label style={{ marginBottom: 0 }}>
                            {this.state.i18n.HowHear}
                          </label>
                          <div class="input-container">
                            <input
                              class="input-field"
                              type="text"
                              placeholder={this.state.i18n.HowHear}
                              name="hear"
                              onChange={(hear) =>
                                this.setState({ hear: hear.target.value })
                              }
                            />
                          </div>
                          {this.state.isBuild ? null : (
                            <>
                              <label style={{ marginBottom: 0 }}>
                                {this.state.i18n.building_no}
                              </label>
                              <div class="input-container">
                                <input
                                  class="input-field"
                                  type="text"
                                  placeholder={this.state.i18n.building_no}
                                  name="building_no"
                                  onChange={(building_no) =>
                                    this.setState({
                                      building_no: building_no.target.value,
                                    })
                                  }
                                  value={this.state.building_no}
                                />
                              </div>
                            </>
                          )}
                          <br />
                          <div class="checkbox Agreement">
                            {this.state.language == "arabic" ? (
                              <div className="CheckDiv">
                                <p>
                                  {this.state.i18n.IAgree}
                                  <a href="/Terms" target="_blank">
                                    {this.state.i18n.termsAconditions}
                                  </a>
                                </p>
                                <label class="containercHECK">
                                  <input
                                    type="checkbox"
                                    name="Agree"
                                    onChange={(Agree) =>
                                      this.setState({
                                        Check: Agree.target.value,
                                      })
                                    }
                                  />
                                  <span class="checkmark"> </span>
                                </label>
                              </div>
                            ) : (
                              <div className="CheckDiv">
                                <label class="containercHECK">
                                  <input
                                    type="checkbox"
                                    name="Agree"
                                    onChange={(Agree) =>
                                      this.setState({
                                        Check: Agree.target.value,
                                      })
                                    }
                                  />
                                  <span class="checkmark"> </span>
                                </label>
                                <p>
                                  {this.state.i18n.IAgree}
                                  <a href="/Terms" target="_blank">
                                    {this.state.i18n.termsAconditions}
                                  </a>
                                </p>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 CartbuttContainer">
                          <a
                            onClick={() => this.PaymentAction()}
                            className="Cartbutt"
                            params={this.state.order_id}
                          >
                            {" "}
                            <i
                              class="fa fa-credit-card"
                              aria-hidden="true"
                            />{" "}
                            {this.state.i18n.ContinueBut}
                          </a>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>{" "}
              {/**Categories  end*/}
            </div>{" "}
            {/** Row End*/}
          </div>
        </div>
      );
    }
  }
}
