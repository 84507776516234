
import React, { Component } from "react";  
import "react-input-range/lib/css/index.css" ; 
import 'pretty-checkbox/src/pretty-checkbox.scss';  
import Global from "../Global.json";
export default class Success extends Component {
    
  constructor(props) {
    super(props);

    this.state = {
      isPageLoad: true,
      value: { min: 2, max: 750 },
      showModal: false,
      quantity: 1,
      show: true,
      max: 5,
      min: 0,
      order_id: props.match.params.OrderID,
      user_id: localStorage.getItem('user_id') ? localStorage.getItem('user_id') : 0,
      language: localStorage.getItem('language') ? localStorage.getItem('language') : "arabic",
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    
     
  }
 
  componentWillMount() { 
     
    if(this.state.language == "arabic"){
      this.setState({
          i18n:Global.ar
      });
    }else {
        this.setState({
            i18n:Global.en
        });
    }
 
    fetch(Global.api_link + "getSuccessOrdersMessage", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: Global.token,
        order_id:this.state.order_id,
        user_id:this.state.user_id,
        lang:this.state.language
      })
    })
      .then(response => response.json())
      .then(result => {
        this.setState({
          Message: result.data,
          OutR:result.outR,
          isPageLoad: false
        }); 
      });
 
  }
 
handleOpenModal () {
    this.setState({ showModal: true });
}
  
handleCloseModal () {
    this.setState({ showModal: false });
}
   
render() {

    if (this.state.isPageLoad) {

      return <div>
          <div className="container22">
            <div  className="Spinner">
              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>  
              </div>

              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>  
              </div>

              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>  
              </div>
            </div>
          </div>
      </div>;
  }else { 
    return (

    <div> {/** Categories Start  */}

      <div className="container"> {/** All Categories Start  */}
        <div className="row"> {/** Row */}
          <div className ="col-md-12 col-sm-12 col-xs-12 AllCatego AllCategoProduct"> {/** Categories Start */}
          <h1>{this.state.i18n.Success}</h1> 
            <div className="col-md-12 col-sm-12 col-xs-12 CartTable Paymentmethod SuccessContainer">
                 
                <p className="MessageSuccess" style={{textAlign:"start"}}>{ this.state.Message}</p>
                {
                  this.state.OutR ?
                  <p className="MessageSuccess" style={{textAlign:"center",color:"red"}}>{ this.state.OutR}</p>
                  :
                  null
                }
                <a href="/Profile#menu3" params={"aaa"}> {this.state.i18n.TrackOrder2} </a>
            </div> 
           </div> {/**Categories  end*/}
        </div> {/** Row End*/}
      </div> 
    </div>

    );
  }
}
}
