
import React, { Component } from "react";    
import Global from "../Global.json";


export default class PaymentWays extends Component {
    
  constructor(props) {
    super(props);

    this.state = {
      isPageLoad: true,  
      Payment : [], 
      language: localStorage.getItem("language")
      ? localStorage.getItem("language")
      : "arabic",

    };
  }

  componentWillMount(){
    this.setState({ isPageLoad: false });
    if (this.state.language == "arabic") {
      this.setState({
        i18n: Global.ar
      });
    } else {
      this.setState({
        i18n: Global.en
      });
    }
    

    fetch(Global.api_link + "getPaymentsMethodsTexts", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language
      })
    })
      .then(response => response.json())
      .then(result => {
        this.setState({
          Payment: result.data,
          isPageLoad: false, 
        });
      }).catch(error => {
        console.error(error);
      });
  }

  MyComponent(text) {
    return <div dangerouslySetInnerHTML={this.createMarkup(text)} />;
  }

  createMarkup(text) {
    return { __html: text };
  }
  
  render() {

    if (this.state.isPageLoad) {

      return <div>
          <div className="container22">
            <div  className="Spinner">
              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>  
              </div>

              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>  
              </div>

              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>  
              </div>
            </div>
          </div>
      </div>;
  }else { 
    return (

    <div> {/** Categories Start  */}

      <div className="container"> {/** All Categories Start  */}
        <div className="row"> {/** Row */}
          <div className ="col-md-12 col-sm-12 col-xs-12 AllCatego About"> {/** Categories Start */}
            <h1> {this.state.i18n.PaymentPay} </h1> 
            
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li style={{width:"49%"}} className="nav-item active"><a class="nav-link active" id="pills-profile-tab" data-toggle="pill" href="#menu1" role="tab" aria-controls="pills-profile" aria-selected="false"> <img src="images/website.png" className="tab_icon" /> <br/>  {this.state.Payment.paymentThreeTitle} </a></li>
                <li style={{width:"49%"}} className="nav-item"><a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#menu2" role="tab" aria-controls="pills-profile" aria-selected="false"><img src="images/save-money.png" className="tab_icon" /> <br/>  {this.state.Payment.paymentTwoTitle}</a></li>
               {/*  <li className="nav-item"><a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#menu3" role="tab" aria-controls="pills-profile" aria-selected="false"><img src="images/pay.png" className="tab_icon" /> <br/> {this.state.Payment.paymentOneTitle}</a></li> */}
            </ul>

            <div  className="tab-content" id="pills-tabContent">
             
            <div id="menu1" className="tab-pane fade show active">
            {this.MyComponent(this.state.Payment.paymentThreeText)}
            </div>
            <div id="menu2" className="tab-pane fade">
                <p>
                
                {this.MyComponent(this.state.Payment.paymentTwoText)}
                </p>
            </div>
           {/*  <div id="menu3" className="tab-pane fade">
                
                <p>
                {this.MyComponent(this.state.Payment.paymentOneText)}
                <br/><br/>

                
                </p>
            </div> */}
        </div> 
          </div> {/**Categories  end*/}
        </div> {/** Row End*/}
      </div>
 
    </div>

    );
  }
}
}
