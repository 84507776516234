
import React, { Component } from "react";  
import "react-input-range/lib/css/index.css" ; 
import 'pretty-checkbox/src/pretty-checkbox.scss'; 
import Global from "../Global.json";

export default class Contact extends Component {
    
  constructor(props) {
    super(props);

    this.state = {
      isPageLoad: true,
      value: { min: 2, max: 750 },
      showModal: false,
      quantity: 1,
      show: true,
      max: 5,
      min: 0,
      language: localStorage.getItem("language")
      ? localStorage.getItem("language")
      : "arabic",
      contactDet:[],
      PhonesC :[]
    };
 
  }

 
  componentWillMount(){
      this.setState({ isPageLoad: false });
      if (this.state.language == "arabic") {
        this.setState({
          i18n: Global.ar
        });
      } else {
        this.setState({
          i18n: Global.en
        });
    }

    fetch(Global.api_link + "getContactPageDetails", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: Global.token,
        lang : this.state.language, 
  
      })
    })
      .then(response => response.json())
      .then(result => {
        console.log(result.data.phones);
        this.setState({
          contactDet : result.data,
          PhonesC : result.data.phones
        })
       
      });
    
  }

Contact() {
  if(this.state.Name && this.state.Phone && this.state.Email && this.state.title && this.state.Message != null  ) {
  fetch(Global.api_link + "addContactMessage", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      token: Global.token,
      name : this.state.Name,
      phone : this.state.Phone,
      email : this.state.Email,
      title : this.state.title,
      message : this.state.Message

    })
  })
    .then(response => response.json())
    .then(result => {
      this.setState({
        isPageLoad : false
      })
    });
}else{
  alert(this.state.i18n.Required)
  this.setState({
    isPageLoad : false
  })
}
}
   
  render() {

    if (this.state.isPageLoad) {

      return <div>
          <div className="container22">
            <div  className="Spinner">
              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>  
              </div>

              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>  
              </div>

              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>  
              </div>
            </div>
          </div>
      </div>;
  }else { 
    function emailIsValid (email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }

    return (

    <div> {/** Categories Start  */}

      <div className="container"> {/** All Categories Start  */}
        <div className="row"> {/** Row */}
          <div className ="col-md-12 col-sm-12 col-xs-12 AllCatego AllCategoProduct"> {/** Categories Start */}
          <h1>{this.state.i18n.ContactUs}</h1> 

          <div className="ContactInfo">
            <div className ="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <h3>  {this.state.i18n.MainPhone} : <h4><a href={"tel:" + this.state.contactDet.main_phone} >{this.state.contactDet.main_phone}</a></h4> </h3>
                <h3>  {this.state.i18n.Email}  : <h4><a href={"mailto:" + this.state.contactDet.main_email }>{this.state.contactDet.main_email}</a></h4> </h3>
               {
                 this.state.PhonesC.map(phoneT=>{
                   if(phoneT.name.length > 20){
                    return(
                      <div>
                        <h3>  {phoneT.name} :  </h3>
                      <h4><a href={ phoneT.phone.match("/^[0-9]+$/") ? "tel:" + phoneT.phone : emailIsValid(phoneT.phone) ? "mailto:" + phoneT.phone : phoneT.phone} >{phoneT.phone}</a></h4>
                      </div>
                                        );
                   }else{
                    return(
                      <h3>  {phoneT.name} : <h4><a href={phoneT.phone.match("/^[0-9]+$/") ? "tel:" + phoneT.phone : emailIsValid(phoneT.phone) ? "mailto:" + phoneT.phone : phoneT.phone} >{phoneT.phone}</a></h4> </h3>
                                        );
                   }
                  
                 })
               }
                
            
            </div>
 
     

   

          </div>
 
            <div className="col-md-12 col-sm-12 col-xs-12 CartTable">
            
                <div id="basic"> 
                <div className ="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div class="input-container">
                        <i class="fa fa-user icon"></i>
                        <input class="input-field" type="text" placeholder={this.state.i18n.FirstNameContact} name="name"   onChange={(name) => this.setState({Name : name.target.value})} />
                    </div>
                </div>

                <div className ="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div class="input-container">
                        <i class="fa fa-envelope icon"></i>
                        <input class="input-field" type="email" placeholder={this.state.i18n.Email} name="email" onChange ={(email) => this.setState({Email : email.target.value})} />
                    </div>
                </div>

                <div className ="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div class="input-container">
                        <i class="fa fa-phone icon"></i>
                        <input class="input-field" type="text" placeholder={this.state.i18n.Phone} name="phone" onChange={(phone) => this.setState({Phone : phone.target.value})} />
                    </div>
                </div>

                <div className ="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div class="input-container">
                        <i class="fa fa-bookmark icon"></i>
                        <input class="input-field" type="text" placeholder={this.state.i18n.title} name="address" onChange ={(address) => this.setState({title : address.target.value})} />
                    </div>
                </div>

               
                <div className ="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="input-container">
                    
                        <i class="fa fa-comments  icon"></i>
                        <textarea rows="8" class="input-field" placeholder={this.state.i18n.Message} name="message" onChange ={(message) => this.setState({Message : message.target.value})} />
                    </div>
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 CartbuttContainer">
                    <a  onClick={()=> {this.setState({isPageLoad : true} , ()=> {this.Contact()})}} className="Cartbutt"> {this.state.i18n.Send}</a>
                </div>
                </div>
                
            </div>
           </div> {/**Categories  end*/}
        </div> {/** Row End*/}
      </div>
 
    </div>

    );
  }
}
}
