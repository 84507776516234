import React, { Component } from "react";
import "react-input-range/lib/css/index.css";
import "pretty-checkbox/src/pretty-checkbox.scss";
import Global from "../Global.json";
export default class Cart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentCity: 0,
      isPageLoad: true,
      value: { min: 2, max: 750 },
      showModal: false,
      quantity: 1,
      show: true,
      CartInstock: [],
      CartOutstock: [],
      allCities: [],
      isCountrySelected: false,
      guest_id: localStorage.getItem("guest_id")
        ? localStorage.getItem("guest_id")
        : 0,
      user_id: localStorage.getItem("user_id")
        ? localStorage.getItem("user_id")
        : 0,
      language: localStorage.getItem("language")
        ? localStorage.getItem("language")
        : "arabic"
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  componentWillMount() {
    if (this.state.language == "arabic") {
      this.setState({
        i18n: Global.ar
      });
    } else {
      this.setState({
        i18n: Global.en
      });
    }

    if (this.state.guest_id == 0) {
      fetch(Global.api_link + "generateGuestID", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          token: Global.token
        })
      })
        .then(response => response.json())
        .then(result => {
          localStorage.setItem("guest_id", result.data);
        });
    }
    this.getUserCartInstockProducts();
    this.getAllCountriesAndCities();
  }

  getUserCartInstockProducts() {
    fetch(Global.api_link + "getUserCartInstockProducts", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: Global.token,
        user_id: this.state.user_id,
        guest_id: this.state.guest_id,
        lang: this.state.language,
        status: 1
      })
    })
      .then(response => response.json())
      .then(result => {
        this.setState({
          CartInstock: result.data
        });
        this.getUserCartOutstockProducts();
      });
  }

  getUserCartOutstockProducts() {
    fetch(Global.api_link + "getUserCartInstockProducts", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: Global.token,
        user_id: this.state.user_id,
        guest_id: this.state.guest_id,
        lang: this.state.language,
        status: 0
      })
    })
      .then(response => response.json())
      .then(result => {
        console.log(result);
        this.setState({
          CartOutstock: result.data
        });

        this.getAllCities();
      });
  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  updateUserCartQTY(pid, qty) {
    //console.log(pid , qty);
    fetch(Global.api_link + "updateUserCartQTY", {
      method: "POST",
      headers: {
        Accept: "application/json"
      },
      body: JSON.stringify({
        token: Global.token,
        cart_id: pid,
        qty: qty
      })
    })
      .then(response => response.json())
      .then(result => {
        this.getUserCartInstockProducts();
      })
      .catch(error => {
        //console.log(error);
        //this.updateUserCartQTY (pid , qty);
      });
  }

  ToggleClick = () => {
    this.setState({
      show: !this.state.show
    });
  };

  handleChange(qty) {
    this.setState({ quantity: qty });
  }

  getAllCities() {
    let qty = 1;

    {
      /**  All Countries */
    }
    fetch(Global.api_link + "getProductDeliveryCities", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language,
        user_id: this.state.user_id,
        guest_id: this.state.guest_id,
        currentCity: this.state.currentCity ? this.state.currentCity : 3,
        selectedCountry: this.state.currentCountry ? this.state.currentCountry : 1,
        qty: qty
      })
    })
      .then(response => response.json())
      .then(result => {
        this.setState({
          allCities: result.data,
          Cities: [],
          // isPageLoad: false
        }, () => {
          console.log(this.state.allCities)
        });
        this.getAllCities2();
        result.data.map(city => {
          if (city.is_selected == 1) {
            this.setState({
              from: city.dateFrom,
              to: city.dateTo
            });
          }
        });
      })
      .catch(error => {
        //console.log(error);
      });
  }

  getAllCountriesAndCities() {
    if (this.state.language == "arabic") {
      this.setState({
        i18n: Global.ar,
      });
    } else {
      this.setState({
        i18n: Global.en,
      });
    }

    fetch(Global.api_link + "getCountriesAndCities", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          CountriesAndCities: result.data,
          CountryID: result.data.country_id,
        });
      });
  }

  getAllCitiesShow(ContryID) {
    this.state.CountriesAndCities.map((city) => {
      if (city.country_id == ContryID) {
        // this.getAllCities();
        console.log(this.state.allCities.filter(res=>res.country_id == city.country_id))
        this.setState({
          Cities: this.state.allCities.filter(res=>res.country_id == city.country_id),
        }, ()=> {
          console.log(this.state.Cities)
        });
        if (city.is_selected == 1) {
          this.setState({
            from: city.dateFrom,
            to: city.dateTo
          });
        } else {
          this.setState({
            from: "",
            to: ""
          });

        }
      }
    });
  }

  
  getAllCitiesShow2(ContryID) {
    this.state.CountriesAndCities.map((city) => {
      if (city.country_id == ContryID) {
        this.setState({
          Cities2: city.cities,
        });
      }
    });
  }

  getAllCities2() {
    let qty = 0;

    {
      /**  All Countries */
    }
    fetch(Global.api_link + "getProductDeliveryCities", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language,
        user_id: this.state.user_id,
        guest_id: this.state.guest_id,
        currentCity: this.state.currentCity2 ? this.state.currentCity2 : 3,
        qty: qty
      })
    })
      .then(response => response.json())
      .then(result => {
        console.log(result);
        this.setState({
          Cities2: result.data,
          isPageLoad: false
        }, () => {
          console.log(this.Cities2)
        });

        result.data.map(city => {
          if (city.is_selected == 1) {
            this.setState({
              from2: city.dateFrom,
              to2: city.dateTo
            });
          }
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    if (this.state.isPageLoad) {
      return (
        <div>
          <div className="container22">
            <div className="Spinner">
              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>

              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>

              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          {" "}
          {/** Categories Start  */}
          <div className="container">
            {" "}
            {/** All Categories Start  */}
            <div className="row">
              {" "}
              {/** Row */}
              <div className="col-md-12 col-sm-12 col-xs-12 AllCatego AllCategoProduct">
                {" "}
                {/** Categories Start */}
                {!this.state.CartInstock.length &&
                !this.state.CartOutstock.length ? (
                  <h1> {this.state.i18n.emptyCart}</h1>
                ) : null}
                {this.state.CartInstock.length ? (
                  <div>
                    <h1> {this.state.i18n.AvailableCart}</h1>
                    <div className="CartTable">
                      <div className="MobileCart">
                        <table>
                          <tr>
                            <th>{this.state.i18n.picture}</th>
                            <th>{this.state.i18n.Name}</th>
                            <th>{this.state.i18n.Size}</th>
                            <th>{this.state.i18n.Color}</th>
                            <th>{this.state.i18n.Qty}</th>
                            <th>{this.state.i18n.Delete}</th>
                          </tr>
                          {this.state.CartInstock.map(Cart => {
                            return (
                              <tr>
                                <td>
                                  <div className="CartImage">
                                    <a
                                      href={
                                        "/ProductDetails/" + Cart.product_id
                                      }
                                    >
                                      <img
                                        alt="Image"
                                        src={Global.image_link + Cart.image}
                                      />
                                    </a>
                                  </div>
                                </td>
                                <td>
                                  <a
                                    href={"/ProductDetails/" + Cart.product_id}
                                  >
                                    {Cart.name
                                      ? Cart.name
                                      : Cart.alternative_name}
                                  </a>
                                </td>
                                <td>
                                  {" "}
                                  {Cart.size_name
                                    ? Cart.size_name
                                    : "No Size"}{" "}
                                </td>
                                <td>
                                  {" "}
                                  {Cart.color_name
                                    ? Cart.color_name
                                    : "No Color"}{" "}
                                </td>
                                <td>
                                  <div className="QntMob">
                                    <button
                                      className="QntButt"
                                      onClick={() => {
                                        if (
                                          parseInt(Cart.qty) <
                                          parseInt(Cart.instockQTY)
                                        ) {
                                          this.updateUserCartQTY(
                                            Cart.cart_id,
                                            parseInt(parseInt(Cart.qty) + 1)
                                          );
                                        }
                                      }}
                                    >
                                      +
                                    </button>
                                    <input
                                      className="inputne"
                                      value={
                                        parseInt(Cart.qty) >
                                        parseInt(Cart.instockQTY)
                                          ? Cart.instockQTY
                                          : Cart.qty
                                      }
                                    />
                                    <button
                                      className="QntButtLeft"
                                      onClick={() => {
                                        if (Cart.qty > 1) {
                                          this.updateUserCartQTY(
                                            Cart.cart_id,
                                            parseInt(parseInt(Cart.qty) - 1)
                                          );
                                        }
                                      }}
                                    >
                                      -
                                    </button>
                                  </div>
                                </td>
                                <td>
                                  <a
                                    href="javascript:void(0);"
                                    onClick={() => {
                                      this.setState(
                                        {
                                          isPageLoad: true
                                        },
                                        () => {
                                          this.updateUserCartQTY(
                                            Cart.cart_id,
                                            0
                                          );
                                        }
                                      );
                                    }}
                                  >
                                    {" "}
                                    <i
                                      class="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                        </table>
                      </div>

                      <div className="col-md-12 col-xs-12 MobileCartView">
                        {this.state.CartInstock.map(Cart => {
                          return (
                            <div className="col-md-12 col-xs-12 RowCart">
                              <div className="col-xs-12">
                                {this.state.i18n.picture}
                                <div className="CartImage">
                                  <a
                                    href={"/ProductDetails/" + Cart.product_id}
                                  >
                                    <img
                                      alt="Image"
                                      src={Global.image_link + Cart.image}
                                    />
                                  </a>
                                </div>
                              </div>

                              <div className="col-xs-12 InfoCart">
                                <h3>{this.state.i18n.Name} : </h3>
                                <a href={"/ProductDetails/" + Cart.product_id}>
                                  {Cart.name
                                    ? Cart.name
                                    : Cart.alternative_name}
                                </a>
                              </div>

                              <div className="col-xs-12 InfoCart">
                                <h3>{this.state.i18n.Size} : </h3>
                                {Cart.size_name ? Cart.size_name : "No Size"}
                              </div>

                              <div className="col-xs-12 InfoCart">
                                <h3>{this.state.i18n.Color} : </h3>
                                {Cart.color_name ? Cart.color_name : "No Color"}
                              </div>

                              <div className="col-xs-12 InfoCart">
                                <div className="QntMob">
                                  <button
                                    className="QntButt"
                                    onClick={() => {
                                      if (
                                        parseInt(Cart.qty) <
                                        parseInt(Cart.instockQTY)
                                      ) {
                                        this.updateUserCartQTY(
                                          Cart.cart_id,
                                          parseInt(parseInt(Cart.qty) + 1)
                                        );
                                      }
                                    }}
                                  >
                                    +
                                  </button>
                                  <input
                                    className="inputne"
                                    value={parseInt(Cart.qty)}
                                  />
                                  <button
                                    className="QntButtLeft"
                                    onClick={() => {
                                      if (Cart.qty > 1) {
                                        this.updateUserCartQTY(
                                          Cart.cart_id,
                                          parseInt(parseInt(Cart.qty) - 1)
                                        );
                                      }
                                    }}
                                  >
                                    -
                                  </button>
                                </div>
                              </div>

                              <div className="col-xs-12 DeleteInfoCart">
                                <a
                                  href="javascript:void(0);"
                                  onClick={() => {
                                    this.setState(
                                      {
                                        isPageLoad: true
                                      },
                                      () => {
                                        this.updateUserCartQTY(Cart.cart_id, 0);
                                      }
                                    );
                                  }}
                                >
                                  {" "}
                                  <i class="fa fa-trash" aria-hidden="true"></i>
                                </a>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div style={{ marginTop: 30 }}>
                        <h5>
                          {this.state.i18n.Country} :
                          <h6>
                          <select
                              // class="input-field"
                              name="country"
                              onChange={(e) => {
                                this.setState(
                                  {
                                    selectedCountry: e.target.value,
                                    isCountrySelected: true,
                                    Country: e.target.value,
                                  },
                                  () => {
                                    this.getAllCitiesShow(
                                      this.state.selectedCountry
                                    );
                                  }
                                );
                              }}
                              style={{
                                padding: 5,
                                paddingBottom: 10,
                                height: 40
                              }}
                            >
                              <option disabled selected>
                                {this.state.i18n.Country}
                              </option>
                              {this.state.CountriesAndCities.map((City) => {
                                return (
                                  <option
                                    key={City.country_id}
                                    value={City.country_id}
                                  >
                                    {City.country_name}
                                  </option>
                                );
                              })}
                            </select>
                          </h6>
                        </h5>
                      </div>
                      <div style={{ marginTop: 10 }}>
                        <h5>
                          {this.state.i18n.City} :
                          <h6>
                            <select
                              onChange={e => {
                                this.setState(
                                  {
                                    currentCity: e.target.value
                                  },
                                  () => {
                                    this.getAllCities();
                                  }
                                );
                              }}
                              style={{
                                padding: 5,
                                paddingBottom: 10,
                                height: 40
                              }}
                            >
                              
                              <option disabled selected>
                                {this.state.i18n.City}
                              </option>
                              {this.state.Cities.map(City => {
                                return (
                                  <option
                                    key={City.city_id}
                                    value={City.city_id}
                                    selected={
                                      this.state.currentCity == City.city_id
                                        ? "selected"
                                        : City.is_selected
                                        ? "selected"
                                        : null
                                    }
                                  >
                                    {" "}
                                    {City.city_name}
                                  </option>
                                );
                              })}
                            </select>
                          </h6>
                        </h5>

                        {this.state.from && this.state.to ? (
                          <h5>
                            {this.state.i18n.DeliveryDate}:{" "}
                            <h6>
                              {this.state.from}{" "}
                              <span>{this.state.i18n.DeliveryDateTo}</span>{" "}
                              {this.state.to}{" "}
                            </h6>
                          </h5>
                        ) : null}
                      </div>

                      <div className="CartbuttContainer">
                        <a
                          href={
                            this.state.user_id != 0
                              ? "/Checkout"
                              : "/guest_checkout"
                          }
                          className="Cartbutt"
                        >
                          {" "}
                          <i
                            class="fa fa-credit-card"
                            aria-hidden="true"
                          ></i>{" "}
                          {this.state.i18n.Approved}
                        </a>
                      </div>
                    </div>
                  </div>
                ) : null}
                {this.state.CartOutstock.length ? (
                  <div>
                    <h1>{this.state.i18n.UnAvailableCart}</h1>
                    <div className="CartTable">
                      <div className="MobileCart">
                        <table>
                          <tr>
                            <th>{this.state.i18n.picture}</th>
                            <th>{this.state.i18n.Name}</th>
                            <th>{this.state.i18n.Size}</th>
                            <th>{this.state.i18n.Color}</th>
                            <th>{this.state.i18n.Qty}</th>
                            <th>{this.state.i18n.Delete}</th>
                          </tr>
                          {this.state.CartOutstock.map(Cart => {
                            return (
                              <tr>
                                <td>
                                  <div className="CartImage">
                                    <a
                                      href={
                                        "/ProductDetails/" + Cart.product_id
                                      }
                                    >
                                      <img
                                        alt="Image"
                                        src={Global.image_link + Cart.image}
                                      />
                                    </a>
                                  </div>
                                </td>
                                <td>
                                  <a
                                    href={"/ProductDetails/" + Cart.product_id}
                                  >
                                    {Cart.name
                                      ? Cart.name
                                      : Cart.alternative_name}
                                  </a>
                                </td>
                                <td>
                                  {" "}
                                  {Cart.size_name
                                    ? Cart.size_name
                                    : "No Size"}{" "}
                                </td>
                                <td>
                                  {" "}
                                  {Cart.color_name
                                    ? Cart.color_name
                                    : "No Color"}{" "}
                                </td>
                                <td>
                                  <div className="QntMob">
                                    <button
                                      className="QntButt"
                                      onClick={() => {
                                        this.updateUserCartQTY(
                                          Cart.cart_id,
                                          parseInt(parseInt(Cart.qty) + 1)
                                        );
                                      }}
                                    >
                                      +
                                    </button>
                                    <input
                                      className="inputne"
                                      value={parseInt(Cart.qty)}
                                    />
                                    <button
                                      className="QntButtLeft"
                                      onClick={() => {
                                        if (Cart.qty > 1) {
                                          this.updateUserCartQTY(
                                            Cart.cart_id,
                                            parseInt(parseInt(Cart.qty) - 1)
                                          );
                                        }
                                      }}
                                    >
                                      -
                                    </button>
                                  </div>
                                </td>
                                <td>
                                  <a
                                    href="javascript:void(0);"
                                    onClick={() => {
                                      this.setState(
                                        {
                                          isPageLoad: true
                                        },
                                        () => {
                                          this.updateUserCartQTY(
                                            Cart.cart_id,
                                            0
                                          );
                                        }
                                      );
                                    }}
                                  >
                                    {" "}
                                    <i
                                      class="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                        </table>
                      </div>

                      <div className="col-md-12 col-xs-12 MobileCartView">
                        {this.state.CartOutstock.map(Cart => {
                          return (
                            <div className="col-md-12 col-xs-12 RowCart">
                              <div className="col-xs-12">
                                {this.state.i18n.picture}
                                <div className="CartImage">
                                  <a
                                    href={"/ProductDetails/" + Cart.product_id}
                                  >
                                    <img
                                      alt="Image"
                                      src={Global.image_link + Cart.image}
                                    />
                                  </a>
                                </div>
                              </div>

                              <div className="col-xs-12 InfoCart">
                                <h3>{this.state.i18n.Name} : </h3>
                                <a href={"/ProductDetails/" + Cart.product_id}>
                                  {Cart.name
                                    ? Cart.name
                                    : Cart.alternative_name}
                                </a>
                              </div>

                              <div className="col-xs-12 InfoCart">
                                <h3>{this.state.i18n.Size} : </h3>
                                {Cart.size_name ? Cart.size_name : "No Size"}
                              </div>

                              <div className="col-xs-12 InfoCart">
                                <h3>{this.state.i18n.Color} : </h3>
                                {Cart.color_name ? Cart.color_name : "No Color"}
                              </div>

                              <div className="col-xs-12 InfoCart">
                                <div className="QntMob">
                                  <button
                                    className="QntButt"
                                    onClick={() => {
                                      this.updateUserCartQTY(
                                        Cart.cart_id,
                                        parseInt(parseInt(Cart.qty) + 1)
                                      );
                                    }}
                                  >
                                    +
                                  </button>
                                  <input
                                    className="inputne"
                                    value={parseInt(Cart.qty)}
                                  />
                                  <button
                                    className="QntButtLeft"
                                    onClick={() => {
                                      if (Cart.qty > 1) {
                                        this.updateUserCartQTY(
                                          Cart.cart_id,
                                          parseInt(parseInt(Cart.qty) - 1)
                                        );
                                      }
                                    }}
                                  >
                                    -
                                  </button>
                                </div>
                              </div>

                              <div className="col-xs-12 DeleteInfoCart">
                                <a
                                  href="javascript:void(0);"
                                  onClick={() => {
                                    this.setState(
                                      {
                                        isPageLoad: true
                                      },
                                      () => {
                                        this.updateUserCartQTY(Cart.cart_id, 0);
                                      }
                                    );
                                  }}
                                >
                                  {" "}
                                  <i class="fa fa-trash" aria-hidden="true"></i>
                                </a>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div style={{ marginTop: 30 }}>

                      <h5>
                          {this.state.i18n.Country} :
                          <h6>
                          <select
                              // class="input-field"
                              name="country"
                              onChange={(e) => {
                                this.setState(
                                  {
                                    selectedCountry2: e.target.value,
                                    isCountrySelected2: true,
                                    Country2: e.target.value,
                                  },
                                  () => {
                                    this.getAllCitiesShow2(
                                      this.state.selectedCountry2
                                    );
                                  }
                                );
                              }}
                              style={{
                                padding: 5,
                                paddingBottom: 10,
                                height: 40
                              }}
                            >
                              <option disabled selected>
                                {this.state.i18n.Country}
                              </option>
                              {this.state.CountriesAndCities.map((City) => {
                                return (
                                  <option
                                    key={City.country_id}
                                    value={City.country_id}
                                  >
                                    {City.country_name}
                                  </option>
                                );
                              })}
                            </select>
                          </h6>
                        </h5>
                        <h5>
                          {this.state.i18n.City} :
                          <h6>
                            <select
                              onChange={e => {
                                this.setState(
                                  {
                                    currentCity2: e.target.value
                                  },
                                  () => {
                                    this.getAllCities2();
                                  }
                                );
                              }}
                              style={{
                                padding: 5,
                                paddingBottom: 10,
                                height: 40
                              }}
                            >
                              {this.state.Cities2.map(City => {
                                return (
                                  <option
                                    key={City.city_id}
                                    value={City.city_id}
                                    selected={
                                      this.state.currentCity2 == City.city_id
                                        ? "selected"
                                        : City.is_selected
                                        ? "selected"
                                        : null
                                    }
                                  >
                                    {" "}
                                    {City.city_name}
                                  </option>
                                );
                              })}
                            </select>
                          </h6>
                        </h5>

                        {this.state.from2 && this.state.to2 ? (
                          <h5>
                            {this.state.i18n.DeliveryDate}:{" "}
                            <h6>
                              {this.state.from2}{" "}
                              <span>{this.state.i18n.DeliveryDateTo}</span>{" "}
                              {this.state.to2}{" "}
                            </h6>
                          </h5>
                        ) : null}
                      </div>

                      <div className="CartbuttContainer">
                        <a
                          href={
                            this.state.user_id != 0
                              ? "/CheckoutStock"
                              : "/guest_checkout"
                          }
                          className="Cartbutt"
                        >
                          <i class="fa fa-credit-card" aria-hidden="true"></i>{" "}
                          {this.state.i18n.Approved}
                        </a>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>{" "}
              {/**Categories  end*/}
            </div>{" "}
            {/** Row End*/}
          </div>
        </div>
      );
    }
  }
}
