import React, { Component } from "react";
import Global from "../Global.json";
import LinesEllipsis from 'react-lines-ellipsis'

export default class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPageLoad: true,
      AllCity: [],
      CountriesAndCities: [],
      CountryID: 0,
      FavoriesList : [],
      UserOrder : [],
      currency:localStorage.getItem('currency') ? localStorage.getItem('currency') : "SAR",
      language: localStorage.getItem('language') ? localStorage.getItem('language') : "arabic",
      guest_id: localStorage.getItem("guest_id")
        ? localStorage.getItem("guest_id")
        : 0,
      user_id: localStorage.getItem("user_id")
        ? localStorage.getItem("user_id")
        : 0,
        Password:101010101,
        PasswordCheck:101010101,
        MyOrder : props.match.params.MyOrder,
    };
  }

  componentWillMount() {
   
    if(this.state.user_id == 0){
      window.location.href = "/";
    }
    if(this.state.language == "arabic"){
      this.setState({
          i18n:Global.ar
      });
    }else {
        this.setState({
            i18n:Global.en
        });
    }
    {
      /** Currency Converter */
    }
  
    fetch(Global.api_link + "getCurrencyConvertor", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: Global.token,
        currency: this.state.currency
      })
    })
      .then(response => response.json())
      .then(result => {
        this.setState({
          currencyConverter: result.data
        }); 
      });


      this.getCountriesAndCities();

    
  }

  getCountriesAndCities(){
    fetch(Global.api_link + "getCountriesAndCities", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language
      })
    })
      .then(response => response.json())
      .then(result => {
        this.setState({
          CountriesAndCities: result.data
        },()=>{
          this.getUserProfile();
          this.getUserOrdersHistory();
        });

      });
  }

  getUserProfile(){
    fetch(Global.api_link + "getUserProfile", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: Global.token,
        user_id: this.state.user_id
      })
    })
      .then(response => response.json())
      .then(result => {
        let data = result.data[0];
        if(result.data.length > 0){
          this.setState({
            EmailSignUp: data.email,
            UserName: data.name,
            Phone: data.phone1,
            AdditionalPhone: data.phone2,
            Country: data.country,
            City: data.city,
            Address: data.address1,
            Street: data.address2,
            isPageLoad: false,
            selectedCountry:data.country,
            isCountrySelected:true,
            building_no: data.building_no
          });
          this.getAllCities(data.country);
        }else{
          window.location.href = "/";
        }
        
        //console.log(data);
      });
  }

  getAllCities(ContryID) {
    //console.log(this.state.CountriesAndCities);
    this.state.CountriesAndCities.map(city => {
      if (city.country_id == ContryID) {
        this.setState({
          AllCity: city.cities
        });
      }
    });
  }

Logout() {
  fetch(Global.api_link + "generateGuestID", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      token: Global.token
    })
  })
    .then(response => response.json())
    .then(result => {
  
      localStorage.setItem("guest_id", result.data);
      localStorage.removeItem("user_id");
      //localStorage.removeItem("guest_id");
      window.location.href = "/";
      this.setState({ isPageLoad: false });
    });
   
}


  
RemoveFromFav (FavID) {
  {/** Remove product from favorites */} 
  fetch(Global.api_link + 'removeProductFromWishlist', {
    method: 'POST',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token : Global.token,
      product_id: FavID,
      user_id: this.state.user_id,
      guest_id : this.state.guest_id,
    }),
  }).then((response) => response.json())
    .then(
        (result) => {   
             this.setState({ 
              isPageLoad : false
            })
         }
    ); 
}


  UpdateUser() {
    let error = 0;
    let message = "";
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ ;
    if(this.state.Password != 101010101 || this.state.PasswordCheck != 101010101){
      if(this.state.Password != this.state.PasswordCheck) {
        error = 1;
        message = "Password Not Identical";
      }
    }

    if(!this.state.EmailSignUp || !this.state.UserName){
      error = 1;
        message = "Fill all fields";
    }

    if(reg.test(this.state.EmailSignUp) === false) {
      error = 1;
      message = "Enter Valid Email Syntax";
    }
    if(!error) {
      fetch(Global.api_link + "userUpdateProfile", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          token: Global.token,
          email: this.state.EmailSignUp,
          password: this.state.Password ? this.state.Password : 0,
          name: this.state.UserName,
          phone1: this.state.Phone,
          phone2: this.state.AdditionalPhone,
          country: this.state.Country,
          city: this.state.City,
          area: this.state.Address,
          street: this.state.Street,
          building_no: this.state.building_no,
          user_id: this.state.user_id
        })
      })
        .then(response => response.json())
        .then(result => {
        
          this.setState({
            isPageLoad: false
          });
          window.location.reload()
        })
        .catch(error => {
         
        });
    }else {
      alert(message);
    }
    
  }


  getUserFavoriesList () {
    
    fetch(Global.api_link + "getUserFavoriesList", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: Global.token,
        lang:this.state.language,
        user_id: this.state.user_id
      })
    })
      .then(response => response.json())
      .then(result => {
        this.setState({
          FavoriesList: result.data, 
        });
      });

  }
 
  getUserOrdersHistory() {
    fetch(Global.api_link + "getUserOrdersHistory", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: Global.token,
        user_id: this.state.user_id
      })
    })
      .then(response => response.json())
      .then(result => {
        console.log()
        this.setState({
          UserOrder: result.data, 
        });
      });
  }
  render() {
    if (this.state.isPageLoad) {
      return (
        <div>
          <div className="container22">
            <div className="Spinner">
              <div className="dot-container">
                <div className="dot" />
                <div className="dot" />
                <div className="dot" />
              </div>

              <div className="dot-container">
                <div className="dot" />
                <div className="dot" />
                <div className="dot" />
              </div>

              <div className="dot-container">
                <div className="dot" />
                <div className="dot" />
                <div className="dot" />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          {" "}
          {/** Categories Start  */}
          <div className="container">
            {" "}
            {/** All Categories Start  */}
            <div className="row">
              {" "}
              {/** Row */}
              <div className="col-md-12 col-sm-12 col-xs-12 AllCatego About Profile">
                {" "}
                {/** Categories Start */}
                <h1> {this.state.i18n.User} </h1>
                <div className="row no_margin">
                  <div class="col-xs-12 col-lg-3 col-md-3 col-sm-3">
                  <ul
                    className="nav nav-pills mb-3"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className=" nav-item ">
                      {" "}
                      <a
                        class="nav-link "
                        id="pills-home-tab"
                        data-toggle="pill"
                        href="#pills-home"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        {" "}
                        <img
                          src="images/profile-information.png"
                          className="tab_icon"
                        />{" "}
                        <br /> {this.state.i18n.UserInfo}{" "}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        class="nav-link"
                        id="pills-profile-tab"
                        href="/Cart"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                      >
                        {" "}
                        <img src="images/cart.png" className="tab_icon" />{" "}
                        <br /> {this.state.i18n.Cart}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        class="nav-link "
                        id="pills-profile-tab"
                        data-toggle="pill"
                        href="#menu2"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                        onClick = {() => this.getUserFavoriesList()}
                      >
                        <img src="images/file.png" className="tab_icon" />{" "}
                        <br /> {this.state.i18n.Favorites}
                      </a>
                    </li>
                    <li className="nav-item active">
                      <a
                        class="nav-link active"
                        id="pills-profile-tab"
                        data-toggle="pill"
                        href="#menu3"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                        onClick = {() => this.getUserOrdersHistory()}
                      >
                        <img src="images/clipboard.png" className="tab_icon" />
                        <br/> {this.state.i18n.Orders}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href = "#"
                        class="nav-link"
                        id="pills-profile-tab"
                        onClick={() => {
                          this.setState({ isPageLoad: true }, () => {
                            this.Logout();
                          });
                        }}
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                      >
                        <img src="images/exit.png" className="tab_icon" />
                        <br/> {this.state.i18n.Logout}
                      </a>
                    </li>
                  </ul>
                </div>
                  <div class="col-xs-12 col-lg-9 col-md-9 col-sm-9">
                  <div
                    className="col-md-12 col-sm-12 col-xs-12 tab-content"
                    id="pills-tabContent"
                  >
                    <div
                      class="tab-pane fade "
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                    >
                      <form className="">
                        <div id="basic">
                          {" "}
                          {/** Edit Form Start  */}
                          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div class="input-container">
                              <i class="fa fa-user icon" />
                              <input
                                class="input-field"
                                type="text"
                                value={this.state.UserName}
                                placeholder={this.state.FirstName}
                                name="lastname"
                                onChange={lastname =>
                                  this.setState({
                                    UserName: lastname.target.value
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div class="input-container">
                              <i class="fa fa-envelope icon" />
                              <input
                                class="input-field"
                                type="email"
                                value={this.state.EmailSignUp}
                                placeholder={this.state.i18n.Email}
                                 
                                name="email"
                                onChange={email =>
                                  this.setState({
                                    EmailSignUp: email.target.value
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div class="input-container">
                              <i class="fa fa-phone icon" />
                              <input
                                class="input-field"
                                type="text"
                                value={this.state.Phone}
                                placeholder={this.state.i18n.Phone}
                                name="phone"
                                onChange={phone =>
                                  this.setState({ Phone: phone.target.value })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div class="input-container">
                              <i class="fa fa-phone icon" />
                              <input
                                class="input-field"
                                type="text"
                                value={this.state.AdditionalPhone}
                                placeholder={this.state.i18n.Phone2}
                                name="phone2"
                                onChange={phone2 =>
                                  this.setState({
                                    AdditionalPhone: phone2.target.value
                                  })
                                }
                              />
                            </div>
                          </div>
                          
                          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div class="input-container">
                              <i class="fa fa-globe icon" />
                              <select
                                class="input-field"
                                name="country"
                                onChange={e => {
                                  this.setState(
                                    {
                                      selectedCountry: e.target.value,
                                      isCountrySelected: true,
                                      Country: e.target.value,
                                      City:0
                                    },
                                    () => {
                                      this.getAllCities(
                                        this.state.selectedCountry
                                      );
                                     
                                    }
                                  );
                                }}
                              >
                                <option disabled selected>
                                  {this.state.i18n.Country}
                                </option>
                                {this.state.CountriesAndCities.map(City => {
                                  return (
                                    <option
                                      key={City.country_id}
                                      value={City.country_id}
                                      selected={
                                        this.state.Country == City.country_id
                                          ? "selected"
                                          : null
                                      }
                                    >
                                      {City.country_name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          {console.log(this.state.selectedCountry)}
                          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div class="input-container">
                              <i class="fa fa-globe icon" />
                              {this.state.isCountrySelected &&
                              this.state.selectedCountry ? (
                                <select
                                  class="input-field"
                                  name="city"
                                  onChange={city =>
                                    this.setState({ City: city.target.value })
                                  }
                                >
                                  <option  selected>
                                    {this.state.i18n.City}
                                  </option>
                                  {this.state.AllCity.map(City => {
                                    return (
                                      <option
                                        key={City.city_id}
                                        selected={
                                          this.state.City == City.city_id
                                            ? "selected"
                                            : null
                                        }
                                        value={City.city_id}
                                      >
                                        {City.city_name}
                                      </option>
                                    );
                                  })}
                                </select>
                              ) : (
                                <select
                                  class="input-field"
                                  name="city"
                                  onChange={city =>
                                    this.setState({ City: city.target.value })
                                  }
                                >
                                  <option disabled selected>
                                    {this.state.i18n.City}
                                  </option>
                                </select>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div class="input-container">
                              <i class="fa fa-home icon" />
                              <input
                                class="input-field"
                                type="text"
                                value={this.state.Address}
                                placeholder={this.state.i18n.Address}
                                name="address"
                                onChange={address =>
                                  this.setState({
                                    Address: address.target.value
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div class="input-container">
                              <i class="fa fa-road icon" />
                              <input
                                class="input-field"
                                type="text"
                                value={this.state.Street}
                                placeholder={this.state.i18n.Street}
                                name="street"
                                onChange={street =>
                                  this.setState({ Street: street.target.value })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div class="input-container">
                              <i class="fa fa-road icon" />
                              <input
                                class="input-field"
                                type="text"
                                value={this.state.building_no}
                                placeholder={this.state.i18n.building_no}
                                name="street"
                                onChange={street =>
                                  this.setState({ building_no: street.target.value })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div class="input-container">
                              <i class="fa fa-key icon" />
                              <input
                                class="input-field"
                                type="password"
                                placeholder={this.state.i18n.Password}
                                name="password"
                                value={this.state.Password}
                                onChange={password =>
                                  this.setState({
                                    Password: password.target.value
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div class="input-container">
                              <i class="fa fa-key icon" />
                              <input
                                class="input-field"
                                type="password"
                                placeholder={this.state.i18n.RePass}
                                name="passwordCheck"
                                value={this.state.PasswordCheck}
                                onChange={passwordCheck =>
                                  this.setState({
                                    PasswordCheck: passwordCheck.target.value
                                  })
                                }
                              />
                            </div>
                          </div>
                         
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 CartbuttContainer">
                            <a
                              onClick={() => this.UpdateUser()}
                              className="Cartbutt"
                            >
                              {" "}
                              {this.state.i18n.Save}{" "}
                            </a>
                          </div>
                        </div>{" "}
                        {/** Signup Form End  */}
                      </form>
                    </div>
                    <div id="menu2" className="tab-pane fade">
                      <div className="col-md-12 col-sm-12 col-xs-12">
                       {/** Product Start */}
                       {
                         this.state.FavoriesList.length ? 
                         this.state.FavoriesList.map(Fav => {
                          let CurrencyPrice = parseFloat(parseFloat(Fav.sale_price) * parseFloat(this.state.currencyConverter)).toFixed(1);
                          let Price = parseFloat(parseFloat(Fav.price) * parseFloat(this.state.currencyConverter)).toFixed(1);

                           return(
                            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                              <div className="item">
                                <a href={"/ProductDetails/" +Fav.id }  title="مشاهدة">
                                  <div className="Product">
                                    <div className="thumbnail">
                                      <img src={Global.image_link + Fav.image} alt="dgdg" />
                                    </div>
    
                                    <div className="caption">
                                      <h3>{parseFloat(Fav.sale_price) ?  CurrencyPrice : Price} {this.state.currency}</h3>
                                      <p>
                                      {Fav.name ? Fav.name : Fav.alternative_name}
                                      </p> 
                                    </div>
                                  </div>
                                </a>
                                <a
                                      onClick={() => {
                                        this.setState({ isPageLoad: true }, () => {
                                          this.RemoveFromFav(Fav.id)
                                        });
                                      }} 
                                        title="حذف"
                                        className="ProfileTrash"
                                      >
                                        <i class="fa fa-trash" />
                                      </a>
                              </div>
                            </div>
                           );
                         })
                       : <h3>{this.state.i18n.FavEmpty}</h3>} 
                        {/** Product End */} 
                      </div>
                    </div>

                    <div id="menu3" className="tab-pane fade show active">
                      <table>
                      {this.state.UserOrder.length>0 ?
                        <tr>
                          
                            <th>{this.state.i18n.OrderNumber}</th>
                            <th>{this.state.i18n.OrderDate}</th>
                            <th>{this.state.i18n.View}</th>
                          
                          
                        </tr>:<h4>{this.state.i18n.NoOrder}</h4>}
                         {
                           this.state.UserOrder.map(Order => {
                             return (
                              <tr>
                                <td>{Order.id}</td>
                                <td>{Order.date_time}</td>
                                <td>
                                  <a target="a_blank" href={"https://api.cos2umes.com/Webapi/invoiceLink?token=43abc9a4f620c34d3bbb863dd6c1e472&hash="+ Order.hash +"&user_id="+ this.state.user_id }>
                                    <i class="fa fa-eye" aria-hidden="true" />
                                  </a>
                                </td>
                              </tr>
                             );
                           })
                         }
                      </table>
                    </div>
                  </div>
                </div>
                </div>
              </div>
              {/**Categories  end*/}
            </div>
            {/** Row End*/}
          </div>
        </div>
      );
    }
  }
}
