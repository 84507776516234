import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import Home from "./pages/Home";
import Header from "./pages/Header";
import Footer from "./pages/Footer";
import Categories from "./pages/Categories";
import CategoryProduct from "./pages/CategoryProduct";
import ProductDetails from "./pages/ProductDetails";
import Cart from "./pages/Cart";
import guest_checkout from "./pages/guest_checkout";
import Checkout from "./pages/Checkout";
import Payment from "./pages/Payment";
import About from "./pages/About";
import Delivery from "./pages/Delivery";
import DeliveryPolicy from "./pages/DeliveryPolicy";
import Contact from "./pages/Contact";
import Profile from "./pages/Profile";
import PaymentWays from "./pages/PaymentWays";
import Success from "./pages/Success";
import Login from "./pages/Login";
import CheckoutStock from "./pages/CheckoutStock";
import Search from "./pages/Search";
import SearchCat from "./pages/SearchCat";
import Failed from "./pages/Failed";
import Terms from "./pages/Terms";
import CancelPolicy from "./pages/CancelPolicy";
import FAQ from "./pages/FAQ";

import "./styles.css";


export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      language:
        localStorage.getItem("language") != null
          ? localStorage.getItem("language")
          : "arabic",
      user_id: localStorage.getItem("user_id")
    };

  }


  componentDidMount(){
    /*
    if(this.state.language == "english"){
      require('./stylesEn.css');
    }else{
      //delete require.cache[require.resolve('./stylesEn.css')];
    }*/
  }

  render() {
    return (
      <div>
        <Header />
        <Router>
          <Route exact path="/" component={Home} />
          <Route path="/Categories/:CatName" component={Categories} />
          <Route path="/CategoryProduct/:CatName" component={CategoryProduct} />
          <Route path="/ProductDetails/:productID" component={ProductDetails} />
          <Route path="/Cart" component={Cart} />
          <Route path="/guest_checkout" component={guest_checkout} />
          <Route path="/About" component={About} />
          <Route path="/Terms" component={Terms} />
          <Route path="/Checkout" component={Checkout} />
          <Route path="/Payment/:OrderID" component={Payment} />
          <Route path="/Profile" component={Profile} />
          <Route path="/Delivery" component={Delivery} />
          <Route path="/Returns" component={DeliveryPolicy} />
          <Route path="/Contact" component={Contact} />
          <Route path="/PaymentWays" component={PaymentWays} />
          <Route path="/Success/:OrderID" component={Success} />
          <Route path="/Login" component={Login} />
          <Route path="/CheckoutStock" component={CheckoutStock} />
          <Route path="/Search/:key" component={Search} />
          <Route path="/SearchCat/:keyCatName/:keyCat" component={SearchCat} />
          <Route path="/Failed" component={Failed} />
          <Route path="/CancelPolicy" component={CancelPolicy} />
          <Route path="/FAQ" component={FAQ} />
        </Router>

        <Footer />
      </div>
    );
  }
}
