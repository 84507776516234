import React, { Component } from "react";
import "react-input-range/lib/css/index.css";
import "pretty-checkbox/src/pretty-checkbox.scss";
import Slider from "react-slick";
import ReactImageMagnify from "react-image-magnify";
//import Modal from 'react-modal';
import Global from "../Global.json";
import ReactPlayer from "react-player";
import LinesEllipsis from "react-lines-ellipsis";
import { Button, Modal } from "react-bootstrap";

export default class ProductDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPageLoad: true,
      value: { min: 2, max: 750 },
      showModal: false,
      quantity: 1,
      show: true,
      max: 5,
      min: 0,
      product_images: [],
      sizes: [],
      colors: [],
      productID: props.match.params.productID,
      selectedSize: [],
      selectedColor: [],
      isSizeSelected: false,
      isColorSelected: false,
      currency: localStorage.getItem("currency")
        ? localStorage.getItem("currency")
        : "SAR",
      Cities: [],
      from: "",
      CitySet: false,
      datesSelected: false,
      showMod: false,
      currentCity: 0,
      isShowing: false,
      Accessories: [],
      guest_id: localStorage.getItem("guest_id")
        ? localStorage.getItem("guest_id")
        : 0,
      user_id: localStorage.getItem("user_id")
        ? localStorage.getItem("user_id")
        : 0,
      language: localStorage.getItem("language")
        ? localStorage.getItem("language")
        : "arabic",
      isShowingTwo: false,
    };
    //this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  openModalHandler = () => {
    this.setState({
      isShowing: true,
    });
  };

  closeModalHandler = () => {
    this.setState({
      isShowing: false,
    });
  };

  componentWillMount() {
    if (this.state.language == "arabic") {
      this.setState({
        i18n: Global.ar,
      });
    } else {
      this.setState({
        i18n: Global.en,
      });
    }

    if (this.state.guest_id == 0) {
      fetch(Global.api_link + "generateGuestID", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: Global.token,
        }),
      })
        .then((response) => response.json())
        .then((result) => {
          localStorage.setItem("guest_id", result.data);
        });
    }

    {
      /** Currency Converter */
    }
    fetch(Global.api_link + "getCurrencyConvertor", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        currency: this.state.currency,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState(
          {
            currencyConverter: result.data,
            isPageLoad: false,
          },
          () => {}
        );
      });

    {
      /**  Product Details */
    }
    fetch(Global.api_link + "getProductDetails", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language,
        product_id: this.state.productID,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        let images_arr = result.product_images;

        images_arr.push({
          id: 1,
          image: result.product_info.image,
        });
        let avilable_sizes = result.sizes;
        this.setState(
          {
            ProductName: result.product_info.name,
            alternative_name: result.product_info.alternative_name,
            description: result.product_info.description,
            location_code: result.product_info.location_code,
            IdProduct: result.product_info.id,
            sku: result.product_info.sku,
            show_by_order: result.product_info.show_by_order,
            product_images: images_arr,
            sizes: avilable_sizes,
            video: result.product_info.video,
            price: result.product_info.price,
            sale_price: result.product_info.sale_price,
            main_image: result.product_info.image,
            cat_name: result.product_info.cat_name,
            machine_name: result.product_info.machine_name,
            byOrderNoteText: result.byOrderNoteText,
            inStockNoteText: result.inStockNoteText,
            cat_parent_name: result.cat_parent_name,
            cat_parent_parent_name: result.cat_parent_parent_name,
            global_mosim : result.product_info.is_valid_mosim,
          },
          () => {
            if (avilable_sizes.length > 0) {
              let isSelActive = 0;

              avilable_sizes.map((avilable_size) => {
                if (parseInt(avilable_size.qty) > 0 && isSelActive == 0) {
                  isSelActive = 1;
                  this.setState(
                    {
                      selectedSize: avilable_size,
                      selectedColor: [],
                      isSizeSelected: true,
                      isColorSelected: false,
                      quantity: 1,
                      datesSelected: false,
                      dateFrom: null,
                      dateTo: null,
                    },
                    () => {
                      this.getAllCities();
                    }
                  );
                }
              });

              if (isSelActive == 0) {
                this.setState(
                  {
                    selectedSize: avilable_sizes[0],
                    selectedColor: [],
                    isSizeSelected: true,
                    isColorSelected: false,
                    quantity: 1,
                    datesSelected: false,
                    dateFrom: null,
                    dateTo: null,
                  },
                  () => {
                    this.getAllCities();
                  }
                );
              }
            } else {
              this.setState({
                isPageLoad: false,
              });
            }
          }
        );
      });

    {
      /**   Related Accessories */
    }
    fetch(Global.api_link + "getProductAccessories", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language,
        product_id: this.state.productID,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          isPageLoad: false,
          Accessories: result.data,
        });
      });
    this.checkIfUserFavor();
  }

  checkIfUserFavor() {
    {
      /** Check if product in favorites */
    }
    fetch(Global.api_link + "checkIfUserFavor", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        user_id: this.state.user_id,
        guest_id: this.state.guest_id,
        product_id: this.state.productID,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState(
          {
            isFav: result.data,
            isPageLoad: false,
          },
          () => {}
        );
      });
  }

  getAllCities() {
    let qty = 0;
    if (this.state.selectedSize.has_colors == 0) {
      if (this.state.selectedSize.qty) {
        qty = parseInt(this.state.selectedSize.qty);
        this.setState({ ProductQty: qty });
      }
    } else {
      if (this.state.selectedColor.color_qty) {
        qty = parseInt(this.state.selectedColor.color_qty);
        this.setState({ ProductQty: qty });
      }
    }

    {
      /**  All Countries */
    }
    fetch(Global.api_link + "getProductDeliveryCities", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language,
        user_id: this.state.user_id,
        guest_id: this.state.guest_id,
        currentCity: this.state.currentCity,
        qty: qty,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          Cities: result.data,
          isPageLoad: false,
        });

        result.data.map((city) => {
          if (city.is_selected == 1) {
            this.setState({
              from: city.dateFrom,
              to: city.dateTo,
            });
          }
        });
      });
  }

  AddToCart() {
    {
      /**   Add To cart */
    }
    let psc_id = 0;
    let psc_Qts = 0;
    if (this.state.selectedSize.has_colors == 0) {
      if (this.state.selectedSize.size_id) {
        psc_id = parseInt(this.state.selectedSize.psc_id);
        psc_Qts = parseInt(this.state.selectedSize.qty);
      }
    } else {
      if (this.state.selectedColor.color_id) {
        psc_id = parseInt(this.state.selectedColor.color_psc_id);
        psc_Qts = parseInt(this.state.selectedColor.color_qty);
      }
    }

    if (psc_id == 0) {
      alert(this.state.i18n.SizeAlert);
      this.setState({
        isPageLoad: false,
      });
    } else {
      if (
        parseInt(this.state.quantity) > parseInt(psc_Qts) &&
        parseInt(psc_Qts) > 0
      ) {
        alert(this.state.i18n.OverQty);
      }

      fetch(Global.api_link + "addProductToCart", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: Global.token,
          psc_id: psc_id,
          user_id: this.state.user_id,
          guest_id: this.state.guest_id,
          qty: this.state.quantity,
        }),
      })
        .then((response) => response.json())
        .then((result) => {
          this.setState({
            isPageLoad: false,
            isShowing: true,
          });
        });
    }
  }

  handleCloseModal() {
    this.setState({ showModal: false });
    window.location.reload();
  }

  IncrementItem = () => {
    this.setState({
      quantity: parseInt(this.state.quantity) + 1,
    });
    /*
    this.setState(prevState => {
      if(prevState.quantity < 9) {
        return {
          quantity: prevState.quantity + 1
        }
      } else {
        return null;
      }
    });*/
  };

  DecreaseItem = () => {
    this.setState((prevState) => {
      if (prevState.quantity > 0) {
        return {
          quantity: prevState.quantity - 1,
        };
      } else {
        return null;
      }
    });
  };
  ToggleClick = () => {
    this.setState({
      show: !this.state.show,
    });
  };
  handleChange = (event) => {
    this.setState({ quantity: event.target.value });
  };

  descriptionProduct(text) {
    return <div dangerouslySetInnerHTML={this.createMarkup(text)} />;
  }

  createMarkup(text) {
    return { __html: text };
  }

  onCitySelect(CityS) {
    this.state.Cities.map((city) => {
      if (city.city_id == CityS) {
        this.setState({
          from: city.dateFrom,
          to: city.dateTo,
          datesSelected: true,
          currentCity: CityS,
        });
      }
    });
  }

  AddToFav() {
    {
      /**  Add To Favoruite */
    }
    fetch(Global.api_link + "addProductToWishlist", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        product_id: this.state.productID,
        user_id: this.state.user_id,
        guest_id: this.state.guest_id,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState(
          {
            isPageLoad: true,
            isFav: true,
          },
          () => {
            this.checkIfUserFavor();
          }
        );
      });
  }

  RemoveFromFav() {
    {
      /** Remove product from favorites */
    }
    fetch(Global.api_link + "removeProductFromWishlist", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        product_id: this.state.productID,
        user_id: this.state.user_id,
        guest_id: this.state.guest_id,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState(
          {
            isPageLoad: true,
            isFav: false,
          },
          () => {
            this.checkIfUserFavor();
          }
        );
      });
  }

  render() {
    //const { onCitySelect } = this.props;
    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            //dots: true,
            //arrows : false
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    var settingsModal = {
      dots: false,
      infinite: false,
      speed: 500,
      arrows: true,
      slidesToShow: 2,
      slidesToScroll: 2,
      initialSlide: 0,
      autoplay: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            //dots: true,
            //arrows : false
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    var settingsAccessories = {
      dots: false,
      infinite: false,
      speed: 500,
      arrows: true,
      slidesToShow: 4,
      slidesToScroll: 2,
      initialSlide: 0,
      autoplay: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            //dots: true,
            //arrows : false
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    var compare_dates = function(date1, date2) {
      if (date1 > date2) return true;
      else if (date1 < date2) return false;
      else return false;
    };

    if (this.state.isPageLoad) {
      return (
        <div>
          <div className="container22">
            <div className="Spinner">
              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>

              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>

              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      function isDateBeforeToday(date) {
        return (
          new Date(date.toDateString()) < new Date(new Date().toDateString())
        );
      }
      return (
        <div>
          {" "}
          {/** Categories Start  */}
          <div className="container">
            {" "}
            {/** All Categories Start  */}
            <div className="row">
              {" "}
              {/** Row */}
              <div className="col-md-12 col-sm-12 col-xs-12 AllCatego AllCategoProduct">
                {" "}
                {/** Categories Start */}{" "}
                <a href={"/CategoryProduct/" + this.state.machine_name}>
                  <h1>
                    {this.state.cat_parent_parent_name
                      ? this.state.cat_parent_parent_name + " - "
                      : null}
                    {this.state.cat_parent_name
                      ? this.state.cat_parent_name + " - "
                      : null}
                    {this.state.cat_name}
                  </h1>
                </a>
              </div>{" "}
              {/**Categories  end*/}
            </div>{" "}
            {/** Row End*/}
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 CategoryProducts">
                <div className="row no_margin">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                    {this.state.product_images.length > 0 ? (
                      <div className="ProductDetails">
                        <Slider {...settings}>
                          {this.state.product_images.map((images) => {
                            return (
                              <div className="ProductItem">
                                {/**<ReactImageMagnify
                                {...{
                                  smallImage: {
                                    alt: "Wristwatch by Ted Baker London",
                                    isFluidWidth: true,
                                    enlargedImagePosition: "inside",
                                    src: Global.image_link + images.image
                                  },
                                  largeImage: {
                                    src: Global.image_link + images.image,
                                    width: 560,
                                    height: 1800
                                  },
                                  //enlargedImagePortalId: "mabg"
                                }}
                              />*/}
                                <img src={Global.image_link + images.image} />
                              </div>
                            );
                          })}
                        </Slider>
                      </div>
                    ) : null}

                    {this.state.video ? (
                      <div className="ProductDetailsVideo">
                        <ReactPlayer
                          url={this.state.video}
                          playing
                          width="100%"
                        />
                      </div>
                    ) : null}
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 ProductDet ">
                    <div id="mabg"></div>
                    <h2>
                      {" "}
                      {this.state.ProductName
                        ? this.state.ProductName
                        : this.state.alternative_name}
                    </h2>
                    <h3>
                      {parseFloat(this.state.sale_price)
                        ? parseFloat(
                            parseFloat(this.state.sale_price) *
                              parseFloat(this.state.currencyConverter)
                          ).toFixed(1)
                        : parseFloat(
                            parseFloat(this.state.price) *
                              parseFloat(this.state.currencyConverter)
                          ).toFixed(1)}{" "}
                      {this.state.currency}
                      {this.state.sale_price &&
                      parseFloat(this.state.sale_price) > 0 ? (
                        <h5 className="oldPriceStyle">
                          {parseFloat(
                            parseFloat(this.state.price) *
                              parseFloat(this.state.currencyConverter)
                          ).toFixed(1)}{" "}
                          {this.state.currency}
                        </h5>
                      ) : null}
                    </h3>
                    {this.state.description ? (
                      <h5>
                        {this.state.i18n.Desc} :
                        <h6>
                          {" "}
                          {this.descriptionProduct(this.state.description)}
                        </h6>
                      </h5>
                    ) : null}

                    {this.state.location_code ? (
                      <h4>L.C : {this.state.location_code}</h4>
                    ) : null}
                    {this.state.sku ? (
                      <h4>
                        {this.state.i18n.PID} : {this.state.sku}
                      </h4>
                    ) : null}

                    <p className="br" />
                    <div>
                      {this.state.sizes.length > 0 ? (
                        <div className="ItemsSize">
                          <h3>{this.state.i18n.Size}</h3>
                          {this.state.sizes.map((Size) => {
                          if (( parseInt(Size.qty) > 0 || !parseInt(this.state.global_mosim))) return  (
                              <div className="select-size">
                                <input
                                  type="radio"
                                  name="SizeName"
                                  checked={
                                    this.state.selectedSize == Size
                                      ? true
                                      : false
                                  }
                                  onChange={() => {
                                    this.setState(
                                      {
                                        selectedSize: Size,
                                        selectedColor: [],
                                        isSizeSelected: true,
                                        isColorSelected: false,
                                        quantity: 1,
                                        datesSelected: false,
                                        dateFrom: null,
                                        dateTo: null,
                                        isPageLoad: true,
                                      },
                                      () => {
                                        this.getAllCities();
                                      }
                                    );
                                  }}
                                  id={"size" + Size.size_id}
                                />
                                <label
                                  for={"size" + Size.size_id}
                                  style={
                                    this.state.selectedSize == Size &&
                                    parseInt(Size.qty) > 0
                                      ? { background: "#06b37b", color: "#fff" }
                                      : null
                                  }
                                >
                                  {Size.size_name}
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      ) : null}

                      {this.state.isSizeSelected &&
                      this.state.selectedSize.colors ? (
                        <div className="ItemsSize">
                          {" "}
                          {/** CatalogColors class to get color value */}
                          <h3>{this.state.i18n.Color} </h3>
                          {this.state.selectedSize.colors.map((Color) => {
                            return (
                              <div className="select-size">
                                <input
                                  type="radio"
                                  onChange={() => {
                                    this.setState(
                                      {
                                        selectedColor: Color,
                                        isColorSelected: true,
                                        quantity: 1,
                                        datesSelected: false,
                                        dateFrom: null,
                                        dateTo: null,
                                      },
                                      () => {
                                        this.getAllCities();
                                      }
                                    );
                                  }}
                                  checked={
                                    this.state.isColorSelected &&
                                    Color.color_id ==
                                      this.state.selectedColor.color_id
                                      ? true
                                      : false
                                  }
                                  name="s-size"
                                  id={"color" + Color.color_id}
                                />
                                <label
                                  for={"color" + Color.color_id}
                                  style={
                                    this.state.selectedColor == Color &&
                                    parseInt(Color.color_qty) > 0
                                      ? { background: "#06b37b", color: "#fff" }
                                      : null
                                  }
                                >
                                  {Color.color_name}
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      ) : null}
                    </div>

                    {(this.state.selectedSize.has_colors == 1 &&
                      this.state.isColorSelected) ||
                    (this.state.selectedSize.has_colors == 0 &&
                      this.state.selectedColor) ? (
                      <div>
                        <h5>
                          {this.state.i18n.Details} :{" "}
                          <h6>
                            {this.descriptionProduct(
                              this.state.selectedSize.details
                                ? this.state.selectedSize.details
                                : this.state.selectedColor.color_details
                            )}{" "}
                          </h6>
                        </h5>
                        <h5>
                          
                          {parseInt(this.state.show_by_order) == 0 &&
                          (parseInt(this.state.selectedSize.qty) == 0 ||
                            (this.state.selectedSize.has_colors == 1 &&
                              parseInt(this.state.selectedColor.color_qty) ==
                                0)) ?null :( this.state.i18n.AvailableProduct)}:
                          {parseInt(this.state.show_by_order) == 0 &&
                          (parseInt(this.state.selectedSize.qty) == 0 ||
                            (this.state.selectedSize.has_colors == 1 &&
                              parseInt(this.state.selectedColor.color_qty) ==
                                0)) ? (/*
                            <h6>
                              <div className="UnAvailableCircle">
                                <i class="fa fa-circle" aria-hidden="true"></i>{" "}
                                {this.state.i18n.NotShowByOrderMessage}
                              </div>
                            </h6>
                          */null)  : (
                            <h6>
                              {parseInt(this.state.selectedColor.color_qty) >
                                0 ||
                              parseInt(this.state.selectedSize.qty) > 0 ? (
                                <div className="AvailableCircle">
                                  <i
                                    class="fa fa-circle"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {this.state.i18n.Available}
                                </div>
                              ) : (/*
                                <div className="UnAvailableCircle">
                                  <i
                                    class="fa fa-circle"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {this.state.i18n.Unavailable}
                                </div>
                              */null)}
                            </h6>
                          )}
                        </h5>
                      </div>
                    ) : null}

                    {parseInt(this.state.show_by_order) == 0 &&
                    (parseInt(this.state.selectedSize.qty) == 0 ||
                      (this.state.selectedSize.has_colors == 1 &&
                        parseInt(this.state.selectedColor.color_qty) == 0)) ? (
                      <div>
                        <h5>
                        {parseInt(this.state.show_by_order) == 0 &&
                          (parseInt(this.state.selectedSize.qty) == 0 ||
                            (this.state.selectedSize.has_colors == 1 &&
                              parseInt(this.state.selectedColor.color_qty) ==
                                0)) ?null :(this.state.i18n.Notes)}:{" "}
                          
                          <h6>{parseInt(this.state.show_by_order) == 0 &&
                          (parseInt(this.state.selectedSize.qty) == 0 ||
                            (this.state.selectedSize.has_colors == 1 &&
                              parseInt(this.state.selectedColor.color_qty) ==
                                0)) ?null :(this.state.i18n.NotShowByOrderMessageText)}</h6>
                        </h5>
                        <br />
                      </div>
                    ) : (
                      <div>
                        {(this.state.selectedSize.has_colors == 1 &&
                          this.state.isColorSelected) ||
                        (this.state.selectedSize.has_colors == 0 &&
                          this.state.selectedColor) ? (
                          <div>
                            <h5>
                              {this.state.i18n.City} :
                              <h6>
                                <select
                                  onChange={(e) => {
                                    this.setState(
                                      {
                                        currentCity: e.target.value,
                                      },
                                      () => {
                                        this.getAllCities();
                                      }
                                    );
                                  }}
                                >
                                  {this.state.Cities.map((City) => {
                                    return (
                                      <option
                                        key={City.city_id}
                                        value={City.city_id}
                                        selected={
                                          this.state.currentCity == City.city_id
                                            ? "selected"
                                            : City.is_selected
                                            ? "selected"
                                            : null
                                        }
                                      >
                                        {" "}
                                        {City.city_name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </h6>
                            </h5>
                                  
                            { parseInt(this.state.show_by_order) == 0 &&
                          (parseInt(this.state.selectedSize.qty) == 0 ||
                            (this.state.selectedSize.has_colors == 1 &&
                              parseInt(this.state.selectedColor.color_qty) ==
                                0)) ? this.state.from && this.state.to ? (
                              <h5>
                                {this.state.i18n.DeliveryDate}:{" "}
                                <h6
                                  style={{ color: "green", fontWeight: "bold" }}
                                >
                                  {this.state.from}{" "}
                                  <span style={{ color: "green" }}>
                                    {this.state.i18n.DeliveryDateTo}
                                  </span>{" "}
                                  {this.state.to}{" "}
                                </h6>
                              </h5>
                            ) : null:null}
                            <br />
                            <h5>
                              {this.state.i18n.Notes} :{" "}
                              <h6>
                                {parseInt(this.state.selectedColor.color_qty) >
                                  0 || parseInt(this.state.selectedSize.qty) > 0
                                  ? this.descriptionProduct(
                                      this.state.inStockNoteText
                                    )
                                  : this.descriptionProduct(
                                      this.state.byOrderNoteText
                                    )}
                              </h6>
                            </h5>
                            {parseInt(this.state.selectedColor.color_qty) > 0 ||
                            parseInt(this.state.selectedSize.qty) > 0 ? null : (
                              <div>
                                <button
                                  onClick={() => {
                                    this.setState({
                                      isShowingTwo: true,
                                    });
                                  }}
                                  type="button"
                                  class="AddToCart"
                                  data-toggle="modal"
                                  data-target="#exampleModal"
                                >
                                  {" "}
                                  {this.state.i18n.notifyMe}{" "}
                                </button>
                              </div>
                            )}
                          </div>
                        ) : null}
                      </div>
                    )}

                    {/** 
                <h5>ملاحظات : <h6> جاهز للتسليم الفوري  <a href=""> راجع صفحة الشحن</a></h6></h5><br/>
                */}

                    {(this.state.selectedSize.has_colors == 1 &&
                      this.state.isColorSelected) ||
                    (this.state.selectedSize.has_colors == 0 &&
                      this.state.selectedColor)  ? (
                      <div className="QntFavAdd">
                       {parseInt(this.state.show_by_order) == 0 &&
                          (parseInt(this.state.selectedSize.qty) == 0 ||
                            (this.state.selectedSize.has_colors == 1 &&
                              parseInt(this.state.selectedColor.color_qty) ==
                                0)) ? null :(<><h3>{this.state.i18n.Qty}</h3>
                        <button
                          className="QntButt"
                          onClick={this.IncrementItem}
                        >
                          +
                        </button>
                        <input
                          className="inputne"
                          value={this.state.quantity}
                          onChange={this.handleChange}
                        />
                        <button
                          className="QntButtLeft "
                          onClick={this.DecreaseItem}
                        >
                          -
                        </button></>)}
                        
                        {parseInt(this.state.show_by_order) == 0 &&
                          (parseInt(this.state.selectedSize.qty) == 0 ||
                            (this.state.selectedSize.has_colors == 1 &&
                              parseInt(this.state.selectedColor.color_qty) ==
                                0)) ? null: (      parseInt(this.state.show_by_order) == 1&& parseInt(this.state.global_mosim) == 1) ||
                        parseInt(this.state.selectedSize.qty) > 0 ||
                        parseInt(this.state.selectedColor.color_qty) > 0 ? (
                          <button
                            onClick={() => {
                              if (
                                parseInt(this.state.selectedColor.color_qty) >
                                  0 ||
                                parseInt(this.state.selectedSize.qty) > 0
                              ) {
                                this.setState(
                                  {
                                    isPageLoad: true,
                                  },
                                  () => {
                                    this.AddToCart();
                                  }
                                );
                              } else {
                                this.setState({
                                  isShowingAfterNoQTY: true,
                                });
                              }
                            }}
                            type="button"
                            class="AddToCart"
                            data-toggle="modal"
                            data-target="#exampleModal"
                          >
                            {" "}
                            <i
                              class="fa fa-shopping-cart"
                              aria-hidden="true"
                            ></i>{" "}
                            {this.state.i18n.AddCart}{" "}
                          </button>
                        ) : (
                          <button
                            onClick={() => {}}
                            type="button"
                            class="NotShowByOrder"
                            data-toggle="modal"
                            data-target="#exampleModal"
                          >
                            {" "}
                            <i
                              class="fa fa-shopping-cart"
                              aria-hidden="true"
                            ></i>{" "}
                            {this.state.i18n.NotShowByOrder}{" "}
                          </button>
                        )}

                        <button
                          className={
                            this.state.isFav ? "RemoveToFav" : "AddToFav"
                          }
                          onClick={() =>
                            this.state.isFav
                              ? this.RemoveFromFav()
                              : this.AddToFav()
                          }
                        >
                          {" "}
                          <i
                            className="fa fa-heart"
                            aria-hidden="true"
                          ></i>{" "}
                        </button>
                      </div>
                    ) : null}

                    {this.state.isShowing ? (
                      <div
                        onClick={this.closeModalHandler}
                        className="back-drop"
                      ></div>
                    ) : null}

                    <Modal
                      className="modal"
                      show={this.state.isShowingAfterNoQTY}
                      close={this.closeModalHandler}
                    >
                      <div className="Filters Filterxs">
                        <div className="container">
                          <div className="col-sm-12 col-md-12 col-xs-12">
                            <h4>
                              {this.state.i18n.AddedToCartNoStockReminder}
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <a
                          class="btn btn-secondary "
                          data-dismiss="modal"
                          onClick={() => {
                            this.setState({
                              isShowingAfterNoQTY: false,
                            });
                          }}
                        >
                          {this.state.i18n.Back}
                        </a>
                        <a
                          onClick={() => {
                            this.setState(
                              {
                                isPageLoad: true,
                                isShowingAfterNoQTY: false,
                              },
                              () => {
                                this.AddToCart();
                              }
                            );
                          }}
                          class="btn btn-primary"
                        >
                          {this.state.i18n.Confirm}
                        </a>
                      </div>
                    </Modal>

                    <Modal
                      className="modal"
                      show={this.state.isShowing}
                      close={this.closeModalHandler}
                    >
                      <div className="Filters Filterxs">
                        <p>{this.state.i18n.AddedToCart}</p>
                        <h4>
                          {this.state.Accessories.length > 0
                            ? this.state.i18n.AccessoriesNedded
                            : null}{" "}
                        </h4>
                        <div className="container">
                          <div className="col-sm-12 col-md-12 col-xs-12">
                            {this.state.Accessories.length > 0 ? (
                              <Slider {...settingsModal}>
                                {/** Item Start  */}
                                {this.state.Accessories.map((Access) => {
                                  return (
                                    <div className="item">
                                      <a href={"/ProductDetails/" + Access.id}>
                                        <div className="col-sm-12 col-md-12 col-xs-12">
                                          <div className="Product">
                                            <div className="thumbnail">
                                              <img
                                                src={
                                                  Global.image_link +
                                                  Access.image
                                                }
                                              />
                                            </div>
                                            <div className="caption">
                                              <h5>
                                                <LinesEllipsis
                                                  text={
                                                    Access.name
                                                      ? Access.name
                                                      : Access.alternative_name
                                                  }
                                                  maxLine="1"
                                                  ellipsis="..."
                                                  trimRight
                                                  basedOn="letters"
                                                />
                                              </h5>
                                            </div>
                                          </div>
                                        </div>
                                      </a>
                                    </div>
                                  );
                                })}
                                {/** Item End  */}
                              </Slider>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <a
                          class="btn btn-secondary "
                          data-dismiss="modal"
                          onClick={() => {
                            window.location.reload();
                          }}
                        >
                          {this.state.i18n.Continue}
                        </a>
                        <a href="/Cart" class="btn btn-primary">
                          {this.state.i18n.Approved}
                        </a>
                      </div>
                    </Modal>

                    <Modal
                      className="modal"
                      show={this.state.isShowingTwo}
                      close={() => {
                        this.setState({
                          isShowingTwo: false,
                        });
                      }}
                    >
                      <div className="Filters Filterxs">
                        <p>{this.state.i18n.notifyMe}</p>
                        <div className="container">
                          <div className="col-sm-12 col-md-12 col-xs-12">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no_pad">
                              <div class="input-container">
                                <input
                                  class="input-field"
                                  type="text"
                                  placeholder={this.state.i18n.Phone}
                                  name="phone"
                                  onChange={(phone) =>
                                    this.setState({ Phone: phone.target.value })
                                  }
                                />
                              </div>
                            </div>

                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no_pad">
                              <div class="input-container">
                                <input
                                  class="input-field"
                                  type="text"
                                  placeholder={this.state.i18n.Email}
                                  name="email"
                                  onChange={(email) =>
                                    this.setState({ Email: email.target.value })
                                  }
                                />
                              </div>
                            </div>

                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no_pad">
                              <label>{this.state.i18n.ineedbefore} :</label>
                              <div class="input-container">
                                <input
                                  class="input-field"
                                  
                                  type="date"
                                  placeholder={this.state.i18n.Phone}
                                  min={new Date()
                                    .toLocaleString("en-us", {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                    })
                                    .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2")}
                                  name="date"
                                  onChange={(date) => {
                                    this.setState({
                                      DateNeed: date.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <a
                          class="btn btn-secondary "
                          data-dismiss="modal"
                          onClick={() => {
                            this.setState({
                              isShowingTwo: false,
                            });
                          }}
                        >
                          {this.state.i18n.Close}
                        </a>
                        <a
                          onClick={() => {
                            if (
                              this.state.Email &&
                              this.state.Phone &&
                              this.state.DateNeed
                            ) {
                              if (
                                !isDateBeforeToday(
                                  new Date(this.state.DateNeed)
                                )
                              ) {
                                this.notifyMeSend();
                              } else {
                                alert(this.state.i18n.ineedbeforeDateAlert);
                              }
                            } else {
                              alert(this.state.i18n.Required);
                            }
                          }}
                          class="btn btn-primary"
                        >
                          {this.state.i18n.Send}
                        </a>
                      </div>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.Accessories.length ? (
            <div className="MostSold AccessoriesSlider">
              {" "}
              {/** Accessories Start */}
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <h1>{this.state.i18n.accessory}</h1>
                    <h4>{this.state.i18n.SameProduct}</h4>
                    <Slider {...settingsAccessories}>
                      {/** Item Start  */}
                      {this.state.Accessories.map((Access) => {
                        return (
                          <div className="item">
                            <a href={"/ProductDetails/" + Access.id}>
                              <div className="col-sm-12 col-md-12 col-xs-12">
                                <div className="Product">
                                  <div className="thumbnail">
                                    <img
                                      src={Global.image_link + Access.image}
                                    />
                                  </div>
                                  <div className="caption">
                                    <h5>
                                      <LinesEllipsis
                                        text={
                                          Access.name
                                            ? Access.name
                                            : Access.alternative_name
                                        }
                                        maxLine="1"
                                        ellipsis="..."
                                        trimRight
                                        basedOn="letters"
                                      />
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                        );
                      })}
                      {/** Item End  */}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        /** Accessories End */
      );
    }
  }

  notifyMeSend() {
    fetch(Global.api_link + "notifyMeSend", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        email: this.state.Email,
        phone: this.state.Phone,
        date: this.state.DateNeed,
        user_id: this.state.user_id,
        product_id: this.state.productID,
        size_id: this.state.selectedSize.size_id,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState(
          {
            isShowingTwo: false,
          },
          () => {
            alert(this.state.i18n.notifyMeSuccess);
          }
        );
      });
  }
}
