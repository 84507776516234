
import React, { Component } from "react";    
import Global from "../Global.json";

export default class About extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPageLoad: true,
      value: { min: 2, max: 750 },
      showModal: false,
      quantity: 1,
      show: true,
      max: 5,
      AboutText : " ",
      language: localStorage.getItem("language")
        ? localStorage.getItem("language")
        : "arabic",
      min: 0
    }; 
      
  }

  componentWillMount(){ 
    if (this.state.language == "arabic") {
      this.setState({
        i18n: Global.ar
      });
    } else {
      this.setState({
        i18n: Global.en
      });
    }

    fetch(Global.api_link + "getAboutPageText", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language
      })
    })
      .then(response => response.json())
      .then(result => {
        this.setState({
          AboutText: result.data[0].text,
          isPageLoad: false
        });
      }).catch(error => {
        console.error(error);
      });
  }
   
  MyComponent() {
    return <div dangerouslySetInnerHTML={this.createMarkup()} />;
  }

  createMarkup() {
    return { __html: this.state.AboutText };
  }
  render() {

    if (this.state.isPageLoad) {

      return <div>
          <div className="container22">
            <div  className="Spinner">
              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>  
              </div>

              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>  
              </div>

              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>  
              </div>
            </div>
          </div>
      </div>;
  }else { 
    return (

    <div> {/** Categories Start  */}

      <div className="container"> {/** All Categories Start  */}
        <div className="row"> {/** Row */}
          <div className ="col-md-12 col-sm-12 col-xs-12 AllCatego"> {/** Categories Start */}
          <h1> {this.state.i18n.About} </h1> 
          {this.MyComponent()}

        <h1> {this.state.i18n.DeliveryMenu}  </h1> 
        <p>{this.state.i18n.moreInfo} <a className="PressHere" href="/Delivery">{this.state.i18n.PressHere} </a></p>
          </div> {/**Categories  end*/}
        </div> {/** Row End*/}
      </div>
 
    </div>

    );
  }
}
}
