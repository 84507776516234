import React, { Component } from "react";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import "pretty-checkbox/src/pretty-checkbox.scss";
import Modal from "react-modal";
import Global from "../Global.json";
import LinesEllipsis from "react-lines-ellipsis";

export default class CategoryProduct extends Component {
  constructor(props) {
    super(props);
    console.log(props.match.params.CatName)
    this.state = {
      global_mosim:0,
      isPageLoad: true,
      showModal: false,
      Categories: [],
      inStock: false,
      CatSearch: [],
      Sizes: [],
      FilterAvi: false,
      isSearch: false,
      value: { max: 0, min: 0 },
      SizeSelected: [],
      CatName: props.match.params.CatName,
      currency: localStorage.getItem("currency"),
      language: localStorage.getItem("language")
        ? localStorage.getItem("language")
        : "arabic"
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  componentDidMount() {
    {
      /** Get Category Data, Childs Or Products */
    }
  }

  AllProduct = () => {
    fetch(Global.api_link + "getCategoryChildsOrProducts", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: "43abc9a4f620c34d3bbb863dd6c1e472",
        lang: this.state.language,
        cat_machine_name: this.state.CatName
      })
    })
      .then(response => response.json())
      .then(result => {
        if (result.hasChilds == 0) {
          this.setState({
            Categories: result.data,
            category_name: result.category_name
          });

          this.Filter();
        } else {
          window.location.href = "/Categories/" + this.state.CatName;
        }

        result.data.map(Product => {
          if (Product.instockQTY == null) {
            this.setState({
              Categories: result.data
            });
          }
        });

        console.log(this.state.Categories)
      });
  };

  componentWillMount() {
    if (this.state.language == "arabic") {
      this.setState({
        i18n: Global.ar
      });
    } else {
      this.setState({
        i18n: Global.en
      });
    }

    fetch(Global.api_link + "getCurrencyConvertor", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: Global.token,
        currency: this.state.currency
      })
    })
      .then(response => response.json())
      .then(result => {
        this.setState(
          {
            currencyConverter: result.data
          },
          () => {
            this.AllProduct();
          }
        );
      });


      fetch(Global.api_link + "getProductDetails", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: Global.token,
           product_id: 1,
        }),
      })
        .then((response) => response.json())
        .then((result) => {
           
  console.log(result)
         
           
          this.setState(
            {
               
              global_mosim : parseInt(result.product_info.is_valid_mosim) ,
            },
           
          );
        });
  }

  async Filter() {
    await fetch(Global.api_link + "getCategorySizesAndPrices", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language,
        cat_machine_name: this.state.CatName
      })
    })
      .then(response => response.json())
      .then(result => {
        this.setState({
          Sizes: result.sizes,
          maxVal: parseFloat(
            parseFloat(result.max_price) *
              parseFloat(this.state.currencyConverter)
          ).toFixed(1),
          minVal:
            parseFloat(result.min_price) *
            parseFloat(this.state.currencyConverter).toFixed(1),
          value: {
            min: result.min_price
              ? parseFloat(result.min_price) *
                parseFloat(this.state.currencyConverter).toFixed(1)
              : 0,
            max: result.max_price
              ? parseFloat(
                  parseFloat(result.max_price) *
                    parseFloat(this.state.currencyConverter)
                ).toFixed(1)
              : 0
          },
          maxValFilter: result.max_price,
          minValFilter: result.min_price,
          isPageLoad: false
        });
      })
      .catch(error => {
        console.log(error);
      });
  }
  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  Search() {
    //console.log(this.state.KeySearch);
    if (this.state.KeySearch != "" && this.state.KeySearch) {
      fetch(Global.api_link + "getSearchResultsByCategory", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          token: Global.token,
          lang: this.state.language,
          cat_machine_name: this.state.CatName,
          key: this.state.KeySearch
        })
      })
        .then(response => response.json())
        .then(result => {
          this.setState({
            Categories: result.data,
            isPageLoad: false,
            isSearch: true
          });
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      this.AllProduct();
    }
  }

  FilterApply() {
    console.log( this.state.SizeSelected);
    fetch(Global.api_link + "getCategoryFilteredProducts", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language,
        cat_machine_name: this.state.CatName,
        max_price:
          parseInt(
            parseFloat(this.state.value.max) /
              parseFloat(this.state.currencyConverter)
          ) + 1,
        min_price:
          parseInt(
            parseFloat(this.state.value.min) /
              parseFloat(this.state.currencyConverter)
          ) - 1,
        size_id: this.state.SizeSelected
      })
    })
      .then(response => response.json())
      .then(result => {
        this.setState({
          Categories: result.data,
          isPageLoad: false,
          isSearch: true
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    if (this.state.isPageLoad) {
      return (
        <div>
          <div className="container22">
            <div className="Spinner">
              <div className="dot-container">
                <div className="dot" />
                <div className="dot" />
                <div className="dot" />
              </div>

              <div className="dot-container">
                <div className="dot" />
                <div className="dot" />
                <div className="dot" />
              </div>

              <div className="dot-container">
                <div className="dot" />
                <div className="dot" />
                <div className="dot" />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          {" "}
          {/** Categories Start  */}
          <div className="container">
            {" "}
            {/** All Categories Start  */}
            <div className="row">
              {" "}
              {/** Row */}
              <div className="col-md-12 col-sm-12 col-xs-12 AllCatego AllCategoProduct">
                {" "}
                {/** Categories Start */}
                <h1>{this.state.category_name}</h1>
                {/** Search */}
                {this.state.CatName.includes("___all") ? null : (
                  <div className="input-group md-form form-sm form-1 pl-0">
                    <input
                      className="form-control my-0 py-1"
                      type="text"
                      placeholder={this.state.i18n.SearchIn}
                      aria-label="Search"
                      name="CatSearch"
                      value={this.state.KeySearch}
                      onChange={CatSearch =>
                        this.setState({ KeySearch: CatSearch.target.value })
                      }
                    />
                    <div className="input-group-prepend">
                      <button
                        className="input-group-text ButtonSearch"
                        onClick={e => {
                          this.setState({ isPageLoad: true }, () => {
                            this.Search();
                          });
                        }}
                      >
                        <img alt="Search" src="/images/search.png" />
                      </button>
                    </div>
                  </div>
                )}
                {/** Search End */}
                {/** Ready For Ship Button */}
                { !this.state.global_mosim ? !this.state.FilterAvi  ? (
                  <div
                    className={
                      this.state.CatName.includes("___all")
                        ? "ReadyProduct productReadXS"
                        : "ReadyProduct"
                    }
                  >
                    <button
                      className="input-group-text ButtonSearch"
                      onClick={() => {
                        this.setState({
                          inStock: !this.state.inStock,
                          FilterAvi: true
                        });
                      }}
                    >
                      {!this.state.inStock
                        ? this.state.i18n.SeeAvailable
                        : this.state.i18n.SeeAvailable}
                    </button>
                  </div>
                ) : (
                  <div
                    className={
                      this.state.CatName.includes("___all")
                        ? "ReadyProduct productReadXS"
                        : "ReadyProduct"
                    }
                  >
                    <button
                      className="input-group-text ButtonSearch"
                      onClick={() => {
                        this.setState({
                          inStock: !this.state.inStock,
                          FilterAvi: false
                        });
                      }}
                    >
                      {this.state.i18n.AllProduct}
                    </button>
                  </div>
                ):null}
              </div>{" "}
              {/**Categories  end*/}
            </div>{" "}
            {/** Row End*/}
          </div>
          <div className="container-fluid no_overview">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 CategoryProducts no_pad_xs">
              <div className="row no_margin">
                <button className="FilterIcon" onClick={this.handleOpenModal}>
                  <img alt="filter" src="/images/filter.png" />
                </button>
                <Modal
                  isOpen={this.state.showModal}
                  contentLabel="Minimal Modal Example"
                >
                  <div className="Filters Filterxs">
                    <h3>{this.state.i18n.Price}</h3>
                    <InputRange
                      formatLabel={value => value + " " + this.state.currency}
                      maxValue={this.state.maxVal}
                      minValue={this.state.minVal}
                      value={this.state.value}
                      onChange={value => this.setState({ value })}
                      step={50}
                    />

                    <h4>{this.state.i18n.Size}</h4>
                    <div className="Measur">
                      {this.state.Sizes.map(Size => {
                        return (
                          <div className="CheckDiv">
                            <p>{Size.size_name}</p>
                            <label className="containercHECK">
                              <input
                                type="checkbox"
                                value={Size.size_id}
                                name={Size.size_name}
                                checked={
                                  this.state.SizeSelected.indexOf(
                                    parseInt(Size.size_id)
                                  ) != -1
                                    ? true
                                    : false
                                }
                                onChange={e => {
                                  let size = parseInt(e.target.value);
                                  let arr = this.state.SizeSelected;
                                  if (arr.indexOf(size) != -1) {
                                    arr.splice(arr.indexOf(size), 1);
                                  } else {
                                    arr.push(size);
                                  }

                                  this.setState({
                                    SizeSelected: arr
                                  });
                                }}
                              />
                              <span className="checkmark"> </span>
                            </label>
                          </div>
                        );
                      })}
                    </div>

                    <div className="FilterButt">
                      <button
                        className="input-group-text ButtonSearch"
                        onClick={() => {
                          this.setState({ isPageLoad: true }, () => {
                            this.handleCloseModal();
                            this.FilterApply();
                          });
                        }}
                      >
                        {this.state.i18n.Apply}
                      </button>
                    </div>
                  </div>
                  <button
                    className="CloseFilterModal"
                    onClick={this.handleCloseModal}
                  >
                    <img alt="Close" src="/images/cancel.png" />
                  </button>
                </Modal>

                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-2 col-xs-12 Filters ">
                  <h3>{this.state.i18n.Price}</h3>
                  <InputRange
                    formatLabel={value => value + " " + this.state.currency}
                    maxValue={this.state.maxVal}
                    minValue={this.state.minVal}
                    value={this.state.value}
                    onChange={value => this.setState({ value: value })}
                    step={50}
                  />

                  <h4>
                    {this.state.Sizes.length > 0 ? this.state.i18n.Size : null}
                  </h4>
                  <div className="Measur">
                    {this.state.Sizes.map(Size => {
                      return (
                        <div className="CheckDiv">
                          <p>{Size.size_name}</p>
                          <label className="containercHECK">
                            <input
                              type="checkbox"
                              value={Size.size_id}
                              name={Size.size_name}
                              checked={
                                this.state.SizeSelected.indexOf(
                                  parseInt(Size.size_id)
                                ) != -1
                                  ? true
                                  : false
                              }
                              onChange={e => {
                                let size = parseInt(e.target.value);
                                let arr = this.state.SizeSelected;
                                if (arr.indexOf(size) != -1) {
                                  arr.splice(arr.indexOf(size), 1);
                                } else {
                                  arr.push(size);
                                }

                                this.setState({
                                  SizeSelected: arr
                                });
                              }}
                            />
                            <span className="checkmark"> </span>
                          </label>
                        </div>
                      );
                    })}
                  </div>

                  <div className="FilterButt">
                    <button
                      className="input-group-text ButtonSearch"
                      onClick={() => {
                        this.setState({ isPageLoad: true }, () =>
                          this.FilterApply()
                        );
                      }}
                    >
                      {this.state.i18n.Apply}
                    </button>
                  </div>
                </div>

                <div className="col-xl-10 col-lg-9 col-md-12 col-sm-12 col-xs-12 no_pad_xs">
                  <div className="row no_margin">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 no_pad_xs">
                      <div className="row">
                        {/** Category Thumb Start */}

                        {this.state.Categories.map(Product => {
                          if (
                            (!this.state.global_mosim ||
                            (this.state.global_mosim &&
                              Product.instockQTY != null &&
                              Product.instockQTY != 0 &&
                              Product.instockQTY != "0"))
                          ) {
                            let CurrencyPrice = parseFloat(
                              parseFloat(Product.sale_price) *
                                parseFloat(this.state.currencyConverter)
                            ).toFixed(1);
                            let Price = parseFloat(
                              parseFloat(Product.price) *
                                parseFloat(this.state.currencyConverter)
                            ).toFixed(1);

                            return (
                              <div className="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-xs-6">
                                <a
                                  href={"/ProductDetails/" + Product.id}
                                  className=""
                                  params={Product.id}
                                >
                                  <div className="Category">
                                    <div className="thumbnail">
                                      <img
                                        src={
                                          Product.image != null
                                            ? Global.image_link + Product.image
                                            : "/images/default-no-image.png"
                                        }
                                        alt={
                                          Product.alternative_name +
                                          "_" +
                                          Product.id
                                        }
                                      />
                                      {parseInt(Product.colors_count) > 1 ? (
                                        <div className="thumbnailColorsiew">
                                          <h5>+{this.state.i18n.colors}</h5>
                                        </div>
                                      ) : null}
                                      {Product.sale_percantage && parseInt(Product.sale_percantage) != 0 ? (
                                        <div className="thumbnailDiscountView">
                                          <h5>{this.state.i18n.discount_p} {Product.sale_percantage}%</h5>
                                        </div>
                                      ) : null}
                                    </div>

                                    <div className="caption">
                                      <h2>
                                        {parseFloat(Product.sale_price)
                                          ? CurrencyPrice
                                          : Price}{" "}
                                        {this.state.currency}
                                      </h2>
                                      <h4>
                                        {Product.name
                                          ? Product.name
                                          : Product.alternative_name}
                                      </h4>
                                      {Product.video !== "" ? (
                                        <img
                                          alt="Video"
                                          src="/images/play-button.png"
                                          className="VideoPlay"
                                        />
                                      ) : null}
                                      {Product.instockQTY != null &&
                                      Product.instockQTY != 0 &&
                                      Product.instockQTY != "0" ? (
                                        <img
                                          alt="Video"
                                          src={
                                            this.state.language == "arabic"
                                              ? "/images/instockar.png"
                                              : "/images/instock.png"
                                          }
                                          className="InStock"
                                        />
                                      ) : null}
                                    </div>
                                  </div>
                                </a>
                              </div>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </div>
                      {/** Category Thumb End */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
