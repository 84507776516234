import React, { Component } from "react";
import Slider from "react-slick";
import Global from "../Global.json";
import LinesEllipsis from "react-lines-ellipsis";
import Masonry from "react-masonry-component";

const masonryOptions = {
  transitionDuration: 0
};

const imagesLoadedOptions = { background: ".my-bg-image-el" };

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
      isPageLoad: true,
      MostSelling: [],
      Season: [],
      SeasonName: " ",
      Arrivals: [],
      Categories: [],
      currency: localStorage.getItem("currency")
        ? localStorage.getItem("currency")
        : "SAR",
      language: localStorage.getItem("language")
        ? localStorage.getItem("language")
        : "arabic"
    };
  }

  componentWillMount() {

    if (this.state.language == "arabic") {
      this.setState({
        i18n: Global.ar
      });
    } else {
      this.setState({
        i18n: Global.en
      });
    }

    fetch(Global.api_link + "getMainCategories", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language
      })
    })
      .then(response => response.json())
      .then(result => {
        this.setState(
          {
            Categories: result.data,
            isPageLoad: false
          },
          () => {}
        );
      });

    {
      /** Most Selling Products in Home page */
    }
    fetch(Global.api_link + "getHomeMostSelling", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: "43abc9a4f620c34d3bbb863dd6c1e472",
        lang: this.state.language
      })
    })
      .then(response => response.json())
      .then(result => {
        this.setState(
          {
            MostSelling: result.data
          },
          () => {}
        );
      });

    {
      /** Season Section Home page */
    }
    fetch(Global.api_link + "getHomeSeasonSection", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },

      body: JSON.stringify({
        token: "43abc9a4f620c34d3bbb863dd6c1e472",
        lang: this.state.language
      })
    })
      .then(response => response.json())
      .then(result => {
        this.setState(
          {
            Season: result.data.products,
            SeasonName: result.data.season_name,
            SeasonImage: result.data.season_image
          },
          () => {
            //console.log(this.state.Season)
          }
        );
      });

    {
      /** New Arrivals Home page */
    }

    fetch(Global.api_link + "getHomeNewArrivals", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: "43abc9a4f620c34d3bbb863dd6c1e472",
        lang: this.state.language
      })
    })
      .then(response => response.json())
      .then(result => {
        this.setState(
          {
            Arrivals: result.data
          },
          () => {}
        );
      });

    {
      /** Currency Converter */
    }

    fetch(Global.api_link + "getCurrencyConvertor", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: "43abc9a4f620c34d3bbb863dd6c1e472",
        currency: this.state.currency
      })
    })
      .then(response => response.json())
      .then(result => {
        this.setState(
          {
            currencyConverter: result.data
          },
          () => {}
        );
      });
  }

  render() {
    const { photoIndex, isOpen } = this.state;
    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
            //dots: true,
            //arrows : false
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    if (this.state.isPageLoad) {
      return (
        <div>
          <div className="container22">
            <div className="Spinner">
              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>

              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>

              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          {" "}
          {/** Home Masonry  
          
            <div className="container">
            <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 homeCategoryDiv no_pad">
            <Masonry
                className={'my-gallery-class'} // default ''
                elementType={'div'} // default 'div'
                options={masonryOptions} // default {}
                disableImagesLoaded={true} // default false
                updateOnEachImageLoad={true} // default false and works only if disableImagesLoaded is false
                imagesLoadedOptions={imagesLoadedOptions} // default {}
            >
            
                {this.state.Categories.map(Cat =>{
                  return(
                    
                        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 homeCategoryElement no_pad" style={{minHeight:160}}>
                          <a href={"/Categories/" + Cat.machine_name}>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no_pad">
                          <img
                            src={Cat.image !== null  ? Global.image_link + Cat.image : "/images/default-no-image.png"}
                          />
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no_pad catHomeTitleBottom">
                          <h1>{Cat.name}</h1>
                        </div>
                        </a>
                      </div> 
                  )
                })}
              
 
            </Masonry>
              
                  
              </div>
            </div>
          </div>
          */}
          <div className="container" style={{ overflow: "hidden" }}>
            <div style={ {display:'flex',flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'}} className="col-lg-12 col-md-12 col-sm-12 col-xs-12 homeCategoryDiv no_pad">
            <h1 style={{padding:'10px',borderRadius:'10px',border:'solid 5px #dfb871'}} className="text-center mb-5">{this.state.i18n.Categories}</h1>
              <div className="row">
                {this.state.Categories.map(Cat => {
                  return (
                    <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6 homeCategoryElementNew">
                      <a href={"/Categories/" + Cat.machine_name}>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no_pad homeCategoryElementNewImages">
                          <img
                            src={
                              Cat.image !== null
                                ? Global.image_link + Cat.image + "?v=1"
                                : "/images/default-no-image.png"
                            }
                          />
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no_pad catHomeTitleBottomNew">
                          <h1>{Cat.name}</h1>
                        </div>
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {/* 
        <div className="container"> 
        <div className="row"> 
          <div className ="col-md-12 col-sm-12 col-xs-12 HeaderHome"> 
            <div className ="col-md-6 col-sm-12 col-xs-12"> 
              <a href="#" className="FirstImage">
                <div className="ImageLayer">
                    <img alt="" src="images/file_50c03a61b6_original.png" />
                    <h2> {this.state.i18n.accessory}</h2>
                </div>
              </a>
                <div className ="col-md-12 col-sm-12 col-xs-12 no_pad HeaderSmallImage"> 
                  <div className="col-md-6 col-sm-12 col-xs-12 no_right_pad Child">
                    <a href="#">
                      <div className="ImageLayer">
                          <img alt="" src="images/stock-photo-small-boy-in-a-rabbit-costume-shows-his-finger-to-the-side-studio-shot-gray-background-128324312.png" />
                          <h3>{this.state.i18n.children}</h3>
                      </div>
                    </a>
                  </div>

                  <div className="col-md-6 col-sm-12  col-xs-12 no_left_pad" >
                    <a href="#">
                      <div className="ImageLayer">
                          <img alt="" src="images/stock-photo-a-woman-in-halloween-costume-and-skull-makeup-holding-flowers-93416968.png" />
                          <h4>مكياج و وشم مؤقت</h4>
                      </div> 
                    </a>
                  </div>
                </div> 
            </div> 

            <div className ="col-md-6 col-sm-12 col-xs-12"> 
              <a href="#">
                <div className="ImageLayer">
                    <img alt="" src="images/masks.jpg" />
                    <h1>أقنعه</h1>
                </div>
              </a>
            </div> 
          </div>
        </div> 
      </div>
        */}
          <div className="MostSold">
            {" "}
            {/** Most Sold Start */}
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <h1>{this.state.i18n.MostSelling}</h1>
                  <Slider {...settings}>
                    {/** Product Start */}
                    {this.state.MostSelling.map(Sell => {
                      let SalePrice = parseFloat(
                        parseFloat(Sell.sale_price) *
                          parseFloat(this.state.currencyConverter)
                      ).toFixed(1);
                      let Price = parseFloat(
                        parseFloat(Sell.price) *
                          parseFloat(this.state.currencyConverter)
                      ).toFixed(1);
                      return (
                        <div className="item">
                          <div className="col-sm-12 col-md-12 col-xs-12">
                            <a href={"/ProductDetails/" + Sell.id}>
                              <div className="Product">
                                <div className="thumbnail">
                                  <img
                                    src={
                                      Sell.image !== null
                                        ? Global.image_link +
                                          Sell.image +
                                          "?v=1"
                                        : "/images/default-no-image.png"
                                    }
                                    alt={Sell.alternative_name + "_" + Sell.id}
                                  />
                                </div>

                                <div className="caption">
                                  <h3>
                                    {parseFloat(Sell.sale_price)
                                      ? SalePrice
                                      : Price}{" "}
                                    {this.state.currency}
                                  </h3>
                                  <p>
                                  {
                                        Sell.name
                                          ? Sell.name
                                          : Sell.alternative_name
                                      }
                                  </p>
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      );
                    })}
                    {/** Product End */}
                  </Slider>
                </div>
              </div>
            </div>
          </div>{" "}
          {/** Most Sold End */}
          <div
            className="MostSold Summer"
            style={{
              padding: 0,
              backgroundImage:
                "url(" + Global.image_link + this.state.SeasonImage + ")"
            }}
          >
            {" "}
            {/** Summer Start */}
            <div className="seasonOvere">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <h1 style={{ color: "#fff" }}>{this.state.SeasonName}</h1>
                    <Slider {...settings}>
                      {/** Product Start */}
                      {this.state.Season.map(Season => {
                        let SeasonPrice = parseFloat(
                          parseFloat(Season.sale_price) *
                            parseFloat(this.state.currencyConverter)
                        ).toFixed(1);
                        let PriceSea = parseFloat(
                          parseFloat(Season.price) *
                            parseFloat(this.state.currencyConverter)
                        ).toFixed(1);
                        return (
                          <div className="item">
                            <div className="col-sm-12 col-md-12 col-xs-12">
                              <a href={"/ProductDetails/" + Season.id}>
                                <div className="Product">
                                  <div className="thumbnail">
                                    <img
                                      src={
                                        Season.image !== null
                                          ? Global.image_link +
                                            Season.image +
                                            "?v=1"
                                          : "/images/default-no-image.png"
                                      }
                                      alt={
                                        Season.alternative_name +
                                        "_" +
                                        Season.id
                                      }
                                    />
                                  </div>

                                  <div className="caption">
                                    <h3>
                                      {parseFloat(Season.sale_price)
                                        ? SeasonPrice
                                        : PriceSea}{" "}
                                      {this.state.currency}
                                    </h3>
                                    <p>
                                    {
                                          Season.name
                                            ? Season.name
                                            : Season.alternative_name
                                        }
                                    </p>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        );
                      })}
                      {/** Product End */}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
          {/** Summer End */}
          {/** Recent Product */}
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12 Recents">
                <h1>{this.state.i18n.recent}</h1>
                <Slider {...settings}>
                  {/** Product Start */}
                  {this.state.Arrivals.map(Arrivals => {
                    let ArrivalsPrice = parseFloat(
                      parseFloat(Arrivals.sale_price) *
                        parseFloat(this.state.currencyConverter)
                    ).toFixed(1);
                    let PriceArrivals = parseFloat(
                      parseFloat(Arrivals.price) *
                        parseFloat(this.state.currencyConverter)
                    ).toFixed(1);
                    return (
                      <div className="item">
                        <div className="col-sm-12 col-md-12 col-xs-12">
                          <a href={"/ProductDetails/" + Arrivals.id}>
                            <div className="Product">
                              <div className="thumbnail">
                                <img
                                  src={
                                    Arrivals.image !== null
                                      ? Global.image_link +
                                        Arrivals.image +
                                        "?v=1"
                                      : "/images/default-no-image.png"
                                  }
                                  alt={
                                    Arrivals.alternative_name +
                                    "_" +
                                    Arrivals.id
                                  }
                                />
                              </div>

                              <div className="caption">
                                <h3>
                                  {parseFloat(Arrivals.sale_price)
                                    ? ArrivalsPrice
                                    : PriceArrivals}{" "}
                                  {this.state.currency}
                                </h3>
                                <p>
                                {
                                      Arrivals.name
                                        ? Arrivals.name
                                        : Arrivals.alternative_name
                                    }
                                </p>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    );
                  })}
                  {/** Product End */}
                </Slider>
              </div>
            </div>
          </div>
          {/** Recent Product End */}
        </div>
      );
    }
  }
}
