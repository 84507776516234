import React, { Component } from "react";
import "react-input-range/lib/css/index.css";
import "pretty-checkbox/src/pretty-checkbox.scss";
import Global from "../Global.json";
export default class Payment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPageLoad: true,
      value: { min: 2, max: 750 },
      showModal: false,
      quantity: 1,
      show: true,
      max: 5,
      min: 0,
      Image: "",
      order_id: props.match.params.OrderID,
      user_id: localStorage.getItem("user_id")
        ? localStorage.getItem("user_id")
        : 0,
      language: localStorage.getItem("language")
        ? localStorage.getItem("language")
        : "arabic",
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  componentWillMount() {
    if (this.state.language == "arabic") {
      this.setState({
        i18n: Global.ar,
      });
    } else {
      this.setState({
        i18n: Global.en,
      });
    }
  }

  componentDidMount() {
    {
      /** Currency Converter */
      if (this.state.user_id == 0) {
        window.location.href = "/";
      }
    }

    fetch(Global.api_link + "getCurrencyConvertor", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        currency: this.state.currency,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          currencyConverter: result.data,
        });
        this.getUserCartInstockProducts();
      });

    fetch(Global.api_link + "checkUserOrderProcess", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        user_id: this.state.user_id,
        hash: this.state.order_id,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.data != false) {
          this.setState({
            payment_method_id: result.data.payment_method_id,
            OrderPaymentID: result.data.id,
          });
          this.getBankInfo();
        } else {
          window.location.href = "/Cart";
        }
      });
  }

  getBankInfo() {
    fetch(Global.api_link + "getPaymentsMethodsTexts", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        lang: this.state.language,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          PaymentMD: result.data,
          isPageLoad: false,
        });
      })
      .catch((error) => {});
  }

  cashOnDeliverySuccess() {
    fetch(Global.api_link + "cashOnDeliverySuccess", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        order_id: this.state.OrderPaymentID,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        window.location.href = "/Success/" + this.state.OrderPaymentID;
      });
  }
  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  upload() {
    // Upload the image using the fetch and FormData APIs
    let localUri = this.state.Image;
    let filename = localUri.split("/").pop();

    // Infer the type of the image
    let match = /\.(\w+)$/.exec(filename);
    let type = match ? `image/${match[1]}` : `image`;

    // Upload the image using the fetch and FormData APIs
    let formData = new FormData();
    formData.append("photo", this.state.file);

    fetch(Global.api_link + "uploadImage", {
      method: "POST",

      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState(
          {
            ImageLink: result,
          },
          () => {
            this.completeBankransfer();
          }
        );
      });
  }

  completeBankransfer() {
    fetch(Global.api_link + "bankTransferSuccess", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: Global.token,
        order_id: this.state.OrderPaymentID,
        image: this.state.ImageLink,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        window.location.href = "/Success/" + this.state.OrderPaymentID;
      })
      .catch((error) => {});
  }

  PayPalPage() {
    window.location.href =
      "https://api.cos2umes.com/Webapi/paypalPaymentLink?order_id=" +
      this.state.OrderPaymentID +
      "&token=43abc9a4f620c34d3bbb863dd6c1e472";
  }

  MyComponent(text) {
    return <div dangerouslySetInnerHTML={this.createMarkup(text)} />;
  }

  createMarkup(text) {
    return { __html: text };
  }

  render() {
    if (this.state.isPageLoad) {
      return (
        <div>
          <div className="container22">
            <div className="Spinner">
              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>

              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>

              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      let payfort =
        "https://merwas.net/costumes_react/Webapi/payfortPaymentLink?token=43abc9a4f620c34d3bbb863dd6c1e472&order_id=" +
        this.state.OrderPaymentID;

      let telLink =
        "https://api.cos2umes.com/Webapi/telrPaymentLink?token=43abc9a4f620c34d3bbb863dd6c1e472&order_id=" +
        this.state.OrderPaymentID;
      return (
        <div>
          {" "}
          {/** Categories Start  */}
          <div className="container">
            {" "}
            {/** All Categories Start  */}
            <div className="row">
              {" "}
              {/** Row */}
              <div className="col-md-12 col-sm-12 col-xs-12 AllCatego AllCategoProduct">
                {" "}
                {/** Categories Start */}
                <h1>{this.state.i18n.PaymentMethods}</h1>
                <div className="col-md-12 col-sm-12 col-xs-12 CartTable Paymentmethod">
                  <h3>{this.state.i18n.PayBy} :</h3>
                  <div class="accordion">
                    {this.state.payment_method_id == 4 ? (
                      <div class="card">
                        <div class="card-header">
                          <h2 class="mb-0">
                            <button
                              class="btn btn-link"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseOne"
                              aria-expanded="true"
                            >
                              {this.state.i18n.CreditCard}
                            </button>
                          </h2>
                        </div>

                        <div class="collapse show" aria-labelledby="headingOne">
                          <div class="card-body">
                            <a href={telLink} className="Cartbutt">
                              {" "}
                              <i
                                class="fa fa-money"
                                aria-hidden="true"
                              ></i>{" "}
                              {this.state.i18n.PayNow}
                            </a>

                            <br />
                            <div className="col-md-12 col-sm-12 col-xs-12 creditLogss22">
                              {this.state.language == "arabic" ? (
                                <img src="/images/creds2.png" />
                              ) : (
                                <img src="/images/creds.png" />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.state.payment_method_id == 2 ? (
                      <div class="card">
                        <div class="card-header" id="headingTwo">
                          <h2 class="mb-0">
                            <button
                              class="btn btn-link collapsed show"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              PayPal
                            </button>
                          </h2>
                        </div>
                        <div class="collapse show" aria-labelledby="headingTwo">
                          <div class="card-body">
                            <div class="col-md-12 col-lg-12 checkbox PaymentCjeck Agreement">
                              <div className="CheckDiv">
                                <p>{this.state.i18n.AgreeAlertOnePaypal}</p>
                                <label class="containercHECK">
                                  <input
                                    type="checkbox"
                                    name="PayPalClick"
                                    onChange={(PayPalClick) =>
                                      this.setState({
                                        Clicked: PayPalClick.target.value,
                                      })
                                    }
                                  />
                                  <span class="checkmark"> </span>
                                </label>
                              </div>

                              <div
                                className=" col-xs-12  PaymentInfo"
                                style={{ textAlign: "center" }}
                              >
                                <a
                                  onClick={() => {
                                    if (this.state.Clicked) {
                                      this.setState(
                                        {
                                          isPageLoad: true,
                                        },
                                        () => {
                                          this.PayPalPage();
                                        }
                                      );
                                    } else {
                                      alert(this.state.i18n.AgreeAlertPaypal);
                                    }
                                  }}
                                  params={this.state.order_id}
                                >
                                  <img
                                    style={{ width: 185, cursor: "pointer" }}
                                    src="https://woocommerce.com/wp-content/uploads/2016/04/PayPal-Express@2x.png"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {this.state.payment_method_id == 3 ? (
                      <div class="card">
                        <div class="card-header" id="headingThree">
                          <h2 class="mb-0">
                            <button
                              class="btn btn-link collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseThree"
                              aria-expanded="false"
                            >
                              {this.state.i18n.BankTransfer}
                            </button>
                          </h2>
                        </div>
                        <div
                          class="collapse show"
                          aria-labelledby="headingThree"
                        >
                          <div class="card-body">
                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12  PaymentInfo">
                              {this.MyComponent(
                                this.state.PaymentMD.paymentTwoText
                              )}
                              <br />
                              <label>
                                {this.state.i18n.AgreeAlertBankTrs}
                              </label>{" "}
                              <br />
                              <input
                                type="file"
                                name="bankImage"
                                onChange={(e) => {
                                  this.setState({
                                    file: e.target.files[0],
                                    Image: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div
                              className=" col-xs-12  PaymentInfo"
                              style={{ textAlign: "center" }}
                            >
                              <a
                                onClick={() => {
                                  if (this.state.Image) {
                                    this.setState(
                                      {
                                        isPageLoad: true,
                                      },
                                      () => {
                                        this.upload();
                                      }
                                    );
                                  } else {
                                    alert(this.state.i18n.AgreeAlertBankTrs);
                                  }
                                }}
                                className="Cartbutt"
                                params={this.state.order_id}
                              >
                                {" "}
                                {this.state.i18n.finish}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {this.state.payment_method_id == 1 ? (
                      <div class="card">
                        <div class="card-header" id="headingFour">
                          <h2 class="mb-0">
                            <button
                              class="btn btn-link collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseFour"
                              aria-expanded="false"
                            >
                              {this.state.i18n.CashOnDelivery}
                            </button>
                          </h2>
                        </div>
                        <div
                          class="collapse show"
                          aria-labelledby="headingFour"
                        >
                          <div class="card-body">
                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12  PaymentInfo">
                              <a href="/CancelPolicy" target="_blank">
                                {" "}
                                {this.state.i18n.reviewCancellation}
                              </a>
                              <br />
                              <br />
                              <a
                                onClick={() => {
                                  this.setState(
                                    {
                                      isPageLoad: true,
                                    },
                                    () => {
                                      this.cashOnDeliverySuccess();
                                    }
                                  );
                                }}
                                className="Cartbutt"
                                params={this.state.order_id}
                              >
                                {" "}
                                <i
                                  class="fa fa-money"
                                  aria-hidden="true"
                                ></i>{" "}
                                {this.state.i18n.CashOnDelivery}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>{" "}
              {/**Categories  end*/}
            </div>{" "}
            {/** Row End*/}
          </div>
        </div>
      );
    }
  }
}
