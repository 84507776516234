import React, { Component } from "react";
import "react-input-range/lib/css/index.css" ;
import 'pretty-checkbox/src/pretty-checkbox.scss';
import Global from '../Global.json';
import LinesEllipsis from 'react-lines-ellipsis'

export default class Search extends Component {
    
  constructor(props) {
    super(props);

    this.state = {
      isPageLoad: true,
      value: { min: 2, max: 750 },
      showModal: false,
      Categories : [],
      Search : [],
      KeySearch: props.match.params.key,
      currency:localStorage.getItem("currency"),
      language: localStorage.getItem('language') ? localStorage.getItem('language') : "arabic",
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);

  }
 
componentDidMount(){
    
     
 }
 
 componentWillMount () {
  if(this.state.language == "arabic"){
    this.setState({
        i18n:Global.ar
    });
  }else {
      this.setState({
          i18n:Global.en
      }); 
  }


  fetch(Global.api_link + "getCurrencyConvertor", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      token: Global.token,
      currency: this.state.currency
    })
  })
    .then(response => response.json())
    .then(result => {
      this.setState({
       
        currencyConverter: result.data
      }); 

      this.getResultData();
    }).catch(error=>{
      //console.log(error);
    });
 
 }

 getResultData(){
  fetch(Global.api_link + 'getSearchResults', {
    method: 'POST',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token :  Global.token, 
      key:this.state.KeySearch,
      lang: this.state.language
    }),
}).then((response) => response.json())

    .then(
        (result) => {  
          console.log(result)
            this.setState ({
            isPageLoad: false,
               Search : result.data
           })
        }
    ); 
 }

  handleOpenModal () {
    this.setState({ showModal: true });
  }
  
  handleCloseModal () {
    this.setState({ showModal: false });
  }
  
   
  render() {
    
    if (this.state.isPageLoad) {

      return <div>
          <div className="container22">
            <div  className="Spinner">
              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>  
              </div>

              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>  
              </div>

              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>  
              </div>
            </div>
          </div>
      </div>;
  }else { 
    return (

    <div> {/** Categories Start  */}

      <div className="container"> {/** All Categories Start  */}
        <div className="row"> {/** Row */}
          <div className ="col-md-12 col-sm-12 col-xs-12 AllCatego AllCategoProduct"> {/** Categories Start */}
          <h1>{this.state.i18n.Search}</h1> 
           </div> {/**Categories  end*/}
        </div> {/** Row End*/}
      </div>


      <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 CategoryProducts"> 
            {/** Category Thumb Start */}
              {
                this.state.Search.map(Product => {
      
                  let CurrencyPrice = parseFloat(parseFloat(Product.sale_price) * parseFloat(this.state.currencyConverter)).toFixed(1);
                  let Price = parseFloat(parseFloat(Product.price) * parseFloat(this.state.currencyConverter)).toFixed(1);
                  
                 if (!parseInt(Product.is_valid_mosim) ||( Product.instockQTY > 0 && parseInt(Product.is_valid_mosim) )) return (  <div className ="col-lg-3 col-md-6 col-sm-6 col-xs-6"> 
                      <a href={"/ProductDetails/" + Product.id} className="" params={Product.id}>
                        <div className="Category">
                              <div className = "thumbnail">
                                  <img src = {Global.image_link + Product.image} alt="dgdg" />
                                  {parseInt(Product.colors_count) > 1 ? (
                                        <div className="thumbnailColorsiew">
                                          <h5>+{this.state.i18n.colors}</h5>
                                        </div>
                                      ) : null}
                                      {Product.sale_percantage && parseInt(Product.sale_percantage) != 0 ? (
                                        <div className="thumbnailDiscountView">
                                          <h5>{this.state.i18n.discount_p} {Product.sale_percantage}%</h5>
                                        </div>
                                      ) : null}
                              </div>
                              <div className = "caption">
                                  <h2>{parseFloat(Product.sale_price) ?  CurrencyPrice : Price} {this.state.currency}</h2> 
                                  <h4> 
                                  {Product.name ? Product.name : Product.alternative_name}
                                   </h4>
                                  {Product.video !== "" ? <img alt="Video" src="/images/play-button.png" className="VideoPlay" /> : null}
                                  {Product.instockQTY > 0  ?<img alt="Video" src={
                                            this.state.language == "arabic"
                                              ? "/images/instockar.png"
                                              : "/images/instock.png"
                                          } className="InStock" /> : null }
                              </div>
                        </div>
                      </a>
                    </div>); else return null;
                  
                })
              }
           {/** Category Thumb End */}
          </div>
      </div>
    </div>
    </div>

    );
  }
}
}
